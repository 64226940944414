import React, { useEffect, useState } from 'react';
import './CompraExitosa.css';

import logoAxa from '../../assets/images/cias/axa_logo-01.svg';
import logocompra from '../../assets/images/right-circle.png';
import * as yup from "yup";
import { TEXT_MAX, VALOR_NECESARIO } from '../Utils/Constantes';
import { Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';

var plataforma = [6,1,2,3,4,5];
var tipoPersona = [2];
const useStyles = makeStyles((theme) => ({
  textMark: {
    backgroundColor: "#0e1751"
  }
}));
function FormatNumber({ number }) {
  return (
      new Intl.NumberFormat("ES-MX", {
        style: "currency",
        currency: "MXN"
      }).format(number)
  );
}
export default function RegistroForm(props) {
  const classes = useStyles();

  const [sexo, setSexo] = useState(1);
  const [uberSeguro, setUberSeguro] = useState(null);
  const [no_poliza, setPoliza] = useState(null);
  const [tipoPersona, setTipoPersona] = useState(null);
  const [colaborador, setColaborador] = useState(0);
  const [colaboradorID, setColaboradorID] = useState(null);
  const [datosPersonales, setDatosPersonales] = useState({
    nombre: null,
    apPaterno: null,
    apMaterno: null,
    fechaNacimiento: null,
    genero: null,
    rfc: null,
    telefono: null,
    correo: null,
    contrasena: null,
    confirmar: null,
    edad: null,
  });

  const [cotizacion, setCotizacion] = useState({
    Vehiculo : {
      Uso: null
    },
    RecibosSubsecuentes: null,
    'Importes-Recibos': {
      primer_recibo: {
        PrimaTotal: null
      },
      recibos_subsecuentes: {
        PrimaTotal: null
      }
    }
  });

  const [datosDomicilio, setDatosDomicilio] = useState({
    calle: null,
    ciudad: null,
    colonia: null,
    cp: null,
    estado: null,
    munId: null,
    municipio: null,
    numExt: null,
    numInt: null
  });

  const [registroForm] = useState({
    nombre: null,
    correo: null,
    celular: null,
    genero: null,
    edad: null,
    cp: null,
    modelo:null
  });
  const [auto, setAuto] = useState({
    bran: null,
    class: null,
    description: null,
    model: null,
    type: null,
    noSerie: null,
    placas: null,
  });

  useEffect(() => {
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      setUberSeguro(uberSeguroSto);
      setTipoPersona(uberSeguroSto.tipoPersona);
      if(uberSeguroSto.datosPersonales){
        var hoy = new Date();
        var cumpleanos = new Date(uberSeguroSto.datosPersonales.fechaNacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        uberSeguroSto.datosPersonales.edad = edad;
        setDatosPersonales(uberSeguroSto.datosPersonales);
      }
      if(uberSeguroSto.auto){
        setAuto(uberSeguroSto.auto);
      }
      if(uberSeguroSto.domicilio){
        setDatosDomicilio(uberSeguroSto.domicilio);
      }
      let tp = null;
      if(window.sessionStorage.getItem("type")){
        const typePlan = window.sessionStorage.getItem("type");
        tp = typePlan;
        if(window.sessionStorage.getItem(typePlan)){
          const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
          setCotizacion(cotizacionJson);
        }
      }
      let pol = null;
      if(window.sessionStorage.getItem("poliza")){
        const poliza = window.sessionStorage.getItem("poliza");
        pol = poliza;
        setPoliza(poliza);
      }
      if(tp !== null && pol !== null){
        let revenue = JSON.parse(window.sessionStorage.getItem(tp));
        window.dataLayer.push({
          'event': 'purchase',
          'eventCategory': 'Purchase',
          'eventAction': 'pago',
          'eventLabel': 'Exitoso',
          'cobertura': 'Anual',
          'ecommerce': {
            'purchase': {
              'actionField': {
              'id': pol,
              'revenue': revenue['Importes-Recibos'].primer_recibo.PrimaTotal + revenue['Importes-Recibos'].recibos_subsecuentes.PrimaTotal,
              'cotizacionNumero': revenue.SolicitudID,
              }
            }
          }
        });
      }
    }
  }, []);

  const validationInicioForm = yup.object().shape({
    nombre: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    correo: yup//validarCorreo() ES SERVICIO
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(80,TEXT_MAX),
    celular: yup//validPhoneNumber
      .number()
      .required(VALOR_NECESARIO)
      .nullable(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(9999999999,TEXT_MAX),
    /*genero: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(300,TEXT_MAX),*/
    edad: yup
      .number()
      .required(VALOR_NECESARIO)
      .min(18, VALOR_NECESARIO)
      .max(99,''),
    cp: yup//validaCodigoPostal() ES SERVICIO
      .number()
      .required(VALOR_NECESARIO)
      .min(0, VALOR_NECESARIO)
      .max(99999,''),
    modelo: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(300,TEXT_MAX),
  });
  
  const continuar = (registro) => {
    console.log("continuar",registro);
  };

  return (
    <>
      <Formik validateOnChange={true}
          initialValues={{
            nombre: (registroForm.nombre != null ? registroForm.nombre: ''),
            correo: (registroForm.correo != null ? registroForm.correo: ''),
            celular: (registroForm.celular != null ? registroForm.celular: null),
            //genero: (registroForm.genero != null ? registroForm.genero: null),
            edad: (registroForm.edad != null ? registroForm.edad: null),
            cp: (registroForm.cp != null ? registroForm.cp: null),
            modelo: (registroForm.modelo != null ? registroForm.modelo: null),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const form = {
              nombre: values.nombre,
              correo: values.correo,
              celular: values.celular,
              genero: sexo,
              edad: values.edad,
              cp: values.cp,
              modelo: values.modelo,
            };
            continuar(form, setSubmitting);
          }}
          validationSchema={validationInicioForm}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, }) => (
        <Form>
          <div className="col-12 align-self-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col align-self-center">
                                <div className="tarjeta-form">
                                  <br/><br/>
                                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x app24 align-self-center text-center">
                                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                            <img alt='logo_cia' className="logo-header recuadro" src={logocompra}/>
                                        </div>
                                    ¡GRACIAS POR TU COMPRA!
                                  </div><br/><br/>
                                    <div className="col-12 col-md-12 col-xl-12 px-xs-12x table-containerdetalle">
                                      <div className="row p-20x">
                                        <div className="col-6 col-md-6 col-xl-5 px-xs-5x align-self-right">
                                            <img alt='logo_cia' className="logo-header" src={logoAxa} width="150px" height="70px"/>
                                        </div>
                                        <div className="col-4 col-md-6 col-xl-5 px-xs-5x app24 recuadro" >
                                        {cotizacion.Vehiculo.Uso}
                                        </div>
                                      </div>
                                      <div className="row p-20x">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center recuadro">
                                        No. Poliza: {no_poliza}
                                        </div>
                                      </div>
                                      <div className="row p-20x app36">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-border">
                                        </div>
                                      </div>
                                      <div className="row p-20x">
                                        <div className="col-6 col-md-6 col-xl-5 px-xs-5x app18">
                                          Vehículo
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Descripción:
                                        </div>
                                        <div className="col-9 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro">{auto.description}</span>
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Modelo:
                                        </div>
                                        <div className="col-9 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro">{auto.model}</span>
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                        </div>
                                      </div>
                                      <div className="row p-20x app36">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-border">
                                        </div>
                                      </div>
                                      <div className="row p-20x">
                                        <div className="col-6 col-md-6 col-xl-5 px-xs-5x app18">
                                          Contratante / Asegurado
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Nombre:
                                        </div>
                                        <div className="col-9 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro">{datosPersonales.nombre} {datosPersonales.apPaterno} {datosPersonales.apMaterno}</span>
                                        </div>
                                      </div>
                                      <div className="row p-20x app36">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-border">
                                        </div>
                                      </div>
                                      <div className="row p-20x app36">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                          <FormatNumber number={cotizacion['Importes-Recibos'].primer_recibo.PrimaTotal} />
                                        </div>
                                      </div>
                                      {cotizacion.RecibosSubsecuentes > 0 &&
                                        <div className="row app16">
                                          <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                            + {cotizacion.RecibosSubsecuentes} pago(s) de <FormatNumber number={ (cotizacion['Importes-Recibos'].recibos_subsecuentes.PrimaTotal) / cotizacion.RecibosSubsecuentes } />
                                          </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="row p-20x">
                                      <div className="col-12 col-md-12 col-xl-12 px-xs-12x app16 align-self-center text-center">
                                        Tu poliza se enviará a  <span className="recuadro">{datosPersonales.correo}</span>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
            </div> 
        </Form>
        )}
      </Formik>
                
    </>
  );
}
