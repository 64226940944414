import axios from "axios";
import { PATH } from "../Utils/Constantes";
import { singleParamToDefaultParams } from "../Utils/Utils";

// Create instance called instance
const instance = axios.create({
  baseURL: PATH,
});


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  serie: (params) =>
    instance({
      method: "POST",
      url: "https://api.quattrocrm.mx/auto/publics/validacion-numero-serie",
      data: params
    })
  }
