
import { defaultParams } from "../Utils/Constantes";

export function maxLength(value, maxLength) {
  var res = String(value);
  if(res.length > maxLength){
    return res.substring(0,maxLength);
  } else {
    return res;
  }
}

export function singleParamToDefaultParams(param) {
  defaultParams.f1 = param;
  return defaultParams;
}

export function FormatNumber({number}) {
  if (isNaN(number)) {
    if (number != "NaN" && number != undefined) {
      return (number);
    } else {
      return ('');
    }
  } else {
    return (
        new Intl.NumberFormat("ES-MX", {
          style: "currency",
          currency: "MXN"
        }).format(number)
  );
  }
}

/* funciones para fechaNacimiento */
export const handleChangeFecNac = (newValue, setFieldValue) => {
  //const dateFromField = new Date(newValue);    
  setFieldValue("fechaNacimiento", newValue);
};
/*terminan funciones para fechaNacimiento */
export function formatDDMMYYYY(date) {
  var fechaString = "";
  date = new Date(date);
  fechaString = fechaString
    .concat(formato09(date.getDate()))
    .concat("/")
    .concat(formato09(date.getMonth() + 1))
    .concat("/")
    .concat(date.getFullYear());
  return fechaString;
}

export function formato09(val){
  return (val <= 9 ? "0"+val : val)
}

export function convertDDMMYYYYToDate (fechaNacimiento) {
  const dateParts = fechaNacimiento.split("/");
  const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return date;
};


export function validaMayorEdad (fecha) {
  if(calcularEdad(fecha) >= 18){
    return true;
  } else {
    return false;
  }
};

/**
 * recibe cumple como  fecha completa new Date()
 */
export function calcularEdad(cumple) {
  var hoy = new Date();
  var edad = hoy.getFullYear() - cumple.getFullYear();
  var m = hoy.getMonth() - cumple.getMonth();
  if (m < 0 || (m === 0 && hoy.getDate() < cumple.getDate())) {
      edad--;
  }
  return edad;
}



export function getErrorDes(error, setTipoMensajeErr) {
  if (error.response) {
    // Request made and server responded
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    setTipoMensajeErr(error.response.data.error);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
    setTipoMensajeErr(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    setTipoMensajeErr(error.message);
  }
}