import { useEffect, useState } from 'react';
import useWindowSize from './components/Utils/useWindowSize';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { routes } from './components/Config/Routes';
import Toolbar from './components/Estructura/Toolbar/Toolbar';
import Footer from './components/Estructura/Footer/Footer';

function App() {

  const[isMobile, setIsMobile] = useState((window.innerWidth < 1025)? true : false);
  const size = useWindowSize();

  useEffect(() => {
    setIsMobile((size.width < 1025)? true : false);
  }, [size]);

  return (
    <>
      <Toolbar isMobile={isMobile}></Toolbar>
      <Router>
        <Switch>
          {routes.map((route, i) => (
          <Route key={i} path={route.path} exact
            render={props => (
              <route.component {...props} />
            )}/>
          ))}
          <Redirect exact from="/" to="/inicio" />
        </Switch>
      </Router>
      <Footer/>
    </>
  );
}

export default App;
