import React, { useEffect, useState } from 'react';
import './ResumenCompra.css';
import logoAxa from '../../assets/images/cias/axa_logo-01.svg';
import * as yup from "yup";
import { ERROR_MSG, ERROR_TITULO_MSG, TEXT_MAX, VALOR_NECESARIO } from '../Utils/Constantes';
import { Form, Formik } from 'formik';
import { convertDDMMYYYYToDate, FormatNumber } from '../Utils/Utils';
import Error from '../Estructura/Modal/Error';
import Loading from '../Estructura/Modal/Loading';

export default function RegistroForm(props) {

  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [uberSeguro, setUberSeguro] = useState(null);
  const [tipoPersona, setTipoPersona] = useState(null);
  const [colaborador, setColaborador] = useState(0);
  const [colaboradorID, setColaboradorID] = useState(null);
  const [datosPersonales, setDatosPersonales] = useState({
    nombre: null,
    apPaterno: null,
    apMaterno: null,
    fechaNacimiento: null,
    genero: null,
    rfc: null,
    telefono: null,
    correo: null,
    contrasena: null,
    confirmar: null,
    edad: null,
  });

  const [cotizacion, setCotizacion] = useState({
    Vehiculo : {
      Uso: null
    },
    RecibosSubsecuentes: null,
    'Importes-Recibos': {
      primer_recibo: {
        PrimaTotal: null
      },
      recibos_subsecuentes: {
        PrimaTotal: null
      }
    }
  });

  const [datosDomicilio, setDatosDomicilio] = useState({
    calle: null,
    ciudad: null,
    colonia: null,
    cp: null,
    estado: null,
    munId: null,
    municipio: null,
    numExt: null,
    numInt: null
  });

  const [registroForm] = useState({
    nombre: null,
    correo: null,
    celular: null,
    genero: null,
    edad: null,
    cp: null,
    modelo:null
  });
  const [auto, setAuto] = useState({
    bran: null,
    class: null,
    description: null,
    model: null,
    type: null,
    noSerie: null,
    placas: null,
  });
  useEffect(() => {
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      setUberSeguro(uberSeguroSto);
      setTipoPersona(uberSeguroSto.tipoPersona);
      if(uberSeguroSto.datosPersonales){
        var hoy = new Date();
        var cumpleanos = convertDDMMYYYYToDate(uberSeguroSto.datosPersonales.fechaNacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        uberSeguroSto.datosPersonales.edad = edad;
        setDatosPersonales(uberSeguroSto.datosPersonales);
      }
      if(uberSeguroSto.auto){
        setAuto(uberSeguroSto.auto);
      }
      if(uberSeguroSto.domicilio){
        setDatosDomicilio(uberSeguroSto.domicilio);
      }
      if(window.sessionStorage.getItem("type")){
        const typePlan = window.sessionStorage.getItem("type");
        if(window.sessionStorage.getItem(typePlan)){
          const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
          setCotizacion(cotizacionJson);
        }
      }
      setLoading(false);
    }
  }, []);

  /* funciones para Guardar  Colaborador */
  const handleColaboradorID = (event) => {
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      uberSeguroSto.colaboradorID = event.target.value;
      window.sessionStorage.setItem("uberSeguro",JSON.stringify(uberSeguroSto));
    }
  };
  /*terminan funciones para Guardar Colaborador */

  /* funciones para Colaborador */
  const handleColaborador = (e) => {
    if (colaborador === 0){
      setColaborador(1);
    } else {
      setColaborador(0);
    }
  };
  /*terminan funciones para Colaborador */
  const validationInicioForm = yup.object().shape({
    nombre: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    correo: yup//validarCorreo() ES SERVICIO
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(80,TEXT_MAX),
    celular: yup//validPhoneNumber
      .number()
      .required(VALOR_NECESARIO)
      .nullable(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(9999999999,TEXT_MAX),
    edad: yup
      .number()
      .required(VALOR_NECESARIO)
      .min(18, VALOR_NECESARIO)
      .max(99,''),
    cp: yup//validaCodigoPostal() ES SERVICIO
      .number()
      .required(VALOR_NECESARIO)
      .min(0, VALOR_NECESARIO)
      .max(99999,''),
    modelo: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(300,TEXT_MAX),
  });
  
  const continuar = () => {
    const typePlan = window.sessionStorage.getItem("type");
    const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
    window.dataLayer.push({
      "event" : "cocheapp_resumen",      //Static data,
      "boton_resumen":"Continuar",
      "cotización": cotizacionJson.SolicitudID,
      "aseguradora":"Axa",
      "costo_anual": cotizacionJson['Importes-Recibos'].primer_recibo.PrimaTotal
    });
    props.history.push("/pasarela");
  };

  return (
    <>
      <Error
        open={modalShow}
        handleClose={() => setModalShow(false)}
        titulo={ERROR_TITULO_MSG}
        mensaje={ERROR_MSG}
      />
      <Loading
        open={isLoading}
        handleClose={() => setLoading(false)}
      />
      <Formik validateOnChange={true}
          initialValues={{
            nombre: (registroForm.nombre != null ? registroForm.nombre: ''),
            correo: (registroForm.correo != null ? registroForm.correo: ''),
            celular: (registroForm.celular != null ? registroForm.celular: null),
            edad: (registroForm.edad != null ? registroForm.edad: null),
            cp: (registroForm.cp != null ? registroForm.cp: null),
            modelo: (registroForm.modelo != null ? registroForm.modelo: null),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const form = {
              nombre: values.nombre,
              correo: values.correo,
              celular: values.celular,
              edad: values.edad,
              cp: values.cp,
              modelo: values.modelo,
            };
            continuar(form, setSubmitting);
          }}
          validationSchema={validationInicioForm}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, }) => (
        <Form>
          <div className="col-12 align-self-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col align-self-center">
                                <div className="tarjeta-form">
                                  <br/><br/>
                                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x app24 align-self-center text-center">
                                    RESUMEN DE TU COMPRA
                                  </div><br/><br/>
                                    <div className="col-12 col-md-12 col-xl-12 px-xs-12x table-containerdetalle">
                                      <div className="row p-20x">
                                        <div className="col-6 col-md-6 col-xl-5 px-xs-5x app18">
                                          Vehículo
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Descripción:
                                        </div>
                                        <div className="col-12 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro">{auto.description}</span>
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Modelo:
                                        </div>
                                        <div className="col-9 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro">{auto.model}</span>
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                        </div>
                                      </div>
                                      <div className="row p-20x app36">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-border">
                                        </div>
                                      </div>
                                      <div className="row p-20x">
                                        <div className="col-6 col-md-6 col-xl-5 px-xs-5x app18">
                                          Contratante / Asegurado
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Nombre:
                                        </div>
                                        <div className="col-12 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro">{datosPersonales.nombre} {datosPersonales.apPaterno} {datosPersonales.apMaterno}</span>
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Edad:
                                        </div>
                                        <div className="col-9 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro"> {tipoPersona === 2 ? 'N/A' : datosPersonales.edad}</span>
                                        </div>
                                      </div><div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Sexo:
                                        </div>
                                        <div className="col-9 col-md-9 col-xl-9 px-xs-9x">
                                          {datosPersonales.genero === 0 &&
                                            <span className="recuadro">MASCULINO</span>
                                          }
                                          {datosPersonales.genero === 1 &&
                                            <span className="recuadro">FEMENINO</span>
                                          }
                                        </div>
                                      </div>
                                      <div className="row p-20x app16">
                                        <div className="col-3 col-md-3 col-xl-3 px-xs-3x align-self-right text-right">
                                          Código postal:
                                        </div>
                                        <div className="col-9 col-md-9 col-xl-9 px-xs-9x">
                                          <span className="recuadro"> {datosDomicilio.cp}</span>
                                        </div>
                                      </div>
                                      <div className="row p-20x app36">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-border">
                                        </div>
                                      </div>
                                      <div className="row p-20x">
                                        <div className="col-2 col-md-6 col-xl-5 px-xs-5x align-self-right">
                                            <img alt='logo_cia' className="logo-header" src={logoAxa} width="150px" height="70px"/>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 px-xs-5x app24" >
                                        {cotizacion.Vehiculo.Uso}
                                        </div>
                                      </div>
                                      <div className="row p-20x app36">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                          <FormatNumber number={cotizacion['Importes-Recibos'].primer_recibo.PrimaTotal} />
                                        </div>
                                      </div>
                                      {cotizacion.RecibosSubsecuentes > 0 &&
                                        <div className="row app16">
                                          <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                            + {cotizacion.RecibosSubsecuentes} pago(s) de <FormatNumber number={ (cotizacion['Importes-Recibos'].recibos_subsecuentes.PrimaTotal) / cotizacion.RecibosSubsecuentes } />
                                          </div>
                                        </div>
                                        }
                                      <div className="row app16">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                                          Monto a Pagar
                                        </div>
                                      </div>
                                      <div className="row p-20x">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x app12 align-self-center text-center">
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row p-20x">
                                      <div className="col-12 col-md-12 col-xl-12 px-xs-12x app14 align-self-right text-right linked" onClick={handleColaborador}>
                                        ¿Eres colaborador de Inter?
                                      </div>
                                    </div>
                                    { colaborador === 1 &&
                                      <div className="row p-20x">
                                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x">
                                          <label className="lb-form">Código de colaborador<span className="red"></span></label>
                                          <input name="ref" id="txRef" type="text" placeholder="" autoComplete="off"  maxLength="20" onChange={handleColaboradorID} value={uberSeguro.colaboradorID} />
                                        </div>
                                      </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> 
            </div> 
          <div className="col-md-6 offset-md-3">
            <button id="btn_dp_continuar" name="continuar"  
            className="button-continuar" onClick={continuar}>CONTINUAR</button>
          </div>
        </Form>
        )}
      </Formik>
                
    </>
  );
}
