import axios from "axios";
import { APPSPOT_PATH } from "../Utils/Constantes";

const instanceSpot = axios.create({
  baseURL: APPSPOT_PATH,
});
const paramsAppSpotAutos = (descripcion, modelo) => {
  return {
    desc: descripcion,
    model: modelo,
  }
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  AutosBuscador: (descripcion, modelo) =>
    instanceSpot({
      method: "GET",
      url: "/catalogo",
      params: paramsAppSpotAutos(descripcion, modelo),
    }),
}