import React, { useEffect, useState } from 'react';
import "./Pasarela.css";

import logoDigiCert from "../../assets/images/coche/digicert_secure.svg";
import { Form, Formik } from "formik";
import CustomRadioGroup from "../Forms/CustomRadioGroup";
import CustomRadio from "../Forms/CustomRadio";
import Button from '@mui/material/Button';
import CustomTextField from "../Forms/CustomTextField";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import CustomDatePicker from '../Forms/CustomDatePicker';
import CustomCheckbox from "../Forms/CustomCheckbox";
import { validationForm } from "./ValidationForm";
import PasarelaApi from '../Api/Pasarela';
import Modal from 'react-bootstrap/Modal';
import { ERROR_MSG, ERROR_TITULO_MSG, MAX_LENGTH_CVV, MAX_LENGTH_NUM_TARJETA } from '../Utils/Constantes';
import { maxLength, convertDDMMYYYYToDate, FormatNumber, getErrorDes } from '../Utils/Utils';
import Error from '../Estructura/Modal/Error';
import Loading from '../Estructura/Modal/Loading';

export default function RegistroForm(props) {
  
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [uberSeguro, setUberSeguro] = useState(null);
  const [tipoPersona, setTipoPersona] = useState(null);
  const [tipoMensajeErr, setTipoMensajeErr] = useState(ERROR_MSG);
  const [datosPersonales, setDatosPersonales] = useState({
    nombre: null,
    apPaterno: null,
    apMaterno: null,
    fechaNacimiento: null,
    genero: null,
    rfc: null,
    telefono: null,
    correo: null,
    contrasena: null,
    confirmar: null,
    edad: null,
  });

  const [cotizacion, setCotizacion] = useState({
    Vehiculo : {
      Uso: null
    },
    RecibosSubsecuentes: null,
    'Importes-Recibos': {
      primer_recibo: {
        PrimaTotal: null
      },
      recibos_subsecuentes: {
        PrimaTotal: null
      }
    }
  });

  const [datosDomicilio, setDatosDomicilio] = useState({
    calle: null,
    ciudad: null,
    colonia: null,
    cp: null,
    estado: null,
    munId: null,
    municipio: null,
    numExt: null,
    numInt: null
  });

  const [auto, setAuto] = useState({
    bran: null,
    class: null,
    description: null,
    model: null,
    type: null,
    noSerie: null,
    placas: null,
  });
  
  const handleChangeNumTarjeta = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    const valorFinal = maxLength(value, MAX_LENGTH_NUM_TARJETA);
    setFieldValue(name, valorFinal);
  };
  
  const handleChangeCVV = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    const valorFinal = maxLength(value, MAX_LENGTH_CVV);
    setFieldValue(name, valorFinal);
    document.querySelector('.creditcard').classList.add('flipped');
  };

  const handleBlurCVV = (event, setFieldValue) => {
    document.querySelector('.creditcard').classList.remove('flipped');
  };
  
  const continuar = (values, setSubmitting) => {
    setLoading(true);
    setTipoMensajeErr(ERROR_MSG);
    window.dataLayer.push({
      'event': 'formStep5Submit'
    });
    if(!values.tipoTarjeta){
      setLoading(false);
      setTipoMensajeErr("Debe seleccionar el tipo de tarjeta");
      setModalShow(true);
    } else if(!values.indemnizacion || 
                !values.siniestrado || 
                  !values.avisoPrivacidad || 
                    !values.condicionesGenerales){
                      setLoading(false);
                      setTipoMensajeErr("Debe seleccionar las condiciones");
                      setModalShow(true);
    } else {
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      setUberSeguro(uberSeguroSto);
      setTipoPersona(uberSeguroSto.tipoPersona);
      var typePerson = '';
      var fechaN = '';
      if (uberSeguroSto.tipoPersona === 1) {
        typePerson = "FISICA";
        fechaN = uberSeguroSto.datosPersonales.fechaNacimiento;
      } else {
        typePerson = "MORAL";
        fechaN = uberSeguroSto.datosPersonalesCurrent.fechaNacimiento
      }
      var sex = '';
      if (uberSeguroSto.datosPersonales.genero === 0) {
        sex = "MASCULINO";
      } else {
        sex = "FEMENINO";
      }
      var sexCurrent = '';
      if (uberSeguroSto.datosPersonalesCurrent.genero === 0) {
        sexCurrent = "MASCULINO";
      } else {
        sexCurrent = "FEMENINO";
      }
      if(uberSeguroSto.datosPersonales){
        var hoy = new Date();
        var cumpleanos = new Date(uberSeguroSto.datosPersonales.fechaNacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        uberSeguroSto.datosPersonales.edad = edad;
        setDatosPersonales(uberSeguroSto.datosPersonales);
      }
      if(uberSeguroSto.auto){
        setAuto(uberSeguroSto.auto);
      }
      if(uberSeguroSto.domicilio){
        setDatosDomicilio(uberSeguroSto.domicilio);
      }
      var colaborador = '';
      if (uberSeguroSto.colaboradorID){
        colaborador = uberSeguroSto.colaboradorID;
      }
      if(window.sessionStorage.getItem("type")){
        const typePlan = window.sessionStorage.getItem("type");
        if(window.sessionStorage.getItem(typePlan)){
          const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
          uberSeguroSto.condiciones = {
            indemnizacion: values.indemnizacion,
            siniestrado: values.siniestrado,
            avisoPrivacidad: values.avisoPrivacidad,
            condicionesGenerales: values.condicionesGenerales,
          };
          var fechaHasta = new Date();
          var fechaVencimiento = new Date(values.fechaVencimiento);
          var mesVencimiento = (fechaVencimiento.getMonth()+1);
          fechaVencimiento =  mesVencimiento + "/" + fechaVencimiento.getFullYear();
          var mes = (fechaHasta.getMonth()+1);
          var dia = fechaHasta.getDate();
          if (mes <10){
            mes = '0' + mes;
          }
          if (dia <10){
            dia = '0' + dia;
          }
          var fechaDesde = dia+"/" + mes + "/" + fechaHasta.getFullYear();
          fechaHasta = dia+"/" + mes + "/" + (fechaHasta.getFullYear()+1);
          var params = '';
          if (uberSeguroSto.tipoPersona === 1) {
            params = {
                "SolicitudID": cotizacionJson.SolicitudID.toString(),
                "Uso": typePlan,
                "FormaPago": cotizacionJson.FormaPago,
                "FechaDesde": fechaDesde,
                "FechaHasta": fechaHasta,
                "Titular": {
                    "TipoPersona": typePerson,
                    "FechaNacimiento": fechaN,
                    "Nombre": uberSeguroSto.datosPersonales.nombre,
                    "ApPaterno": uberSeguroSto.datosPersonales.apPaterno,
                    "ApMaterno": uberSeguroSto.datosPersonales.apMaterno,
                    "Sexo": sex,
                    "RFC": uberSeguroSto.datosPersonales.rfc,
                    "Telefono": uberSeguroSto.datosPersonales.telefono,
                    "Correo": uberSeguroSto.datosPersonales.correo,
                    "Direccion": {
                        "CalleNumero": uberSeguroSto.domicilio.calle,
                        "Interior": uberSeguroSto.domicilio.numInt,
                        "CodigoPostal": uberSeguroSto.domicilio.cp,
                        "Colonia": uberSeguroSto.domicilio.ciudad,
                        "Ciudad": uberSeguroSto.domicilio.colonia,
                        "ClavePoblacion": 99,
                        "ClaveEstado": uberSeguroSto.domicilio.estado
                    }
                },
                "Vehiculo": {
                    "Serie": uberSeguroSto.auto.noSerie,
                    "Placas": uberSeguroSto.auto.placas,
                    "Modelo": uberSeguroSto.auto.model,
                    "Marca": uberSeguroSto.auto.bran,
                    "Tipo": uberSeguroSto.auto.type,
                    "Clase": uberSeguroSto.auto.class
                },
                "ConductorHabitual": {
                    "Nombre": uberSeguroSto.datosPersonalesCurrent.nombre,
                    "ApPaterno": uberSeguroSto.datosPersonalesCurrent.apPaterno,
                    "ApMaterno": uberSeguroSto.datosPersonalesCurrent.apMaterno,
                    "FechaNacimiento": uberSeguroSto.datosPersonalesCurrent.fechaNacimiento,
                    "Sexo": sexCurrent,
                    "RFC": uberSeguroSto.datosPersonalesCurrent.rfc
                },
                "PagoElectronico": {
                    "medio": "WEB",
                    "TarjetaTipo": "Credito",
                    "PAN": values.numTarjeta.toString(),
                    "fechaVencimiento": fechaVencimiento,
                    "NombreTitular": values.nombre,
                    "PaternoTitular": values.apPaterno,
                    "MaternoTitular": values.apMaterno,
                    "MSI": 0,
                    "email": uberSeguroSto.datosPersonales.correo
                },
                "NumeroEmpleado": colaborador
            };
          } else {
            params = {
                "SolicitudID": cotizacionJson.SolicitudID.toString(),
                "Uso": typePlan,
                "FormaPago": cotizacionJson.FormaPago,
                "FechaDesde": fechaDesde,
                "FechaHasta": fechaHasta,
                "Titular": {
                    "TipoPersona": typePerson,
                    "FechaNacimiento": fechaN,
                    "Nombre": uberSeguroSto.datosPersonales.nombre,
                    "Sexo": sex,
                    "RFC": uberSeguroSto.datosPersonales.rfc,
                    "Telefono": uberSeguroSto.datosPersonales.telefono,
                    "Correo": uberSeguroSto.datosPersonales.correo,
                    "Direccion": {
                        "CalleNumero": uberSeguroSto.domicilio.calle,
                        "Interior": uberSeguroSto.domicilio.numInt,
                        "CodigoPostal": uberSeguroSto.domicilio.cp,
                        "Colonia": uberSeguroSto.domicilio.ciudad,
                        "Ciudad": uberSeguroSto.domicilio.colonia,
                        "ClavePoblacion": 99,
                        "ClaveEstado": uberSeguroSto.domicilio.estado
                    }
                },
                "Vehiculo": {
                    "Serie": uberSeguroSto.auto.noSerie,
                    "Placas": uberSeguroSto.auto.placas,
                    "Modelo": uberSeguroSto.auto.model,
                    "Marca": uberSeguroSto.auto.bran,
                    "Tipo": uberSeguroSto.auto.type,
                    "Clase": uberSeguroSto.auto.class
                },
                "ConductorHabitual": {
                    "Nombre": uberSeguroSto.datosPersonalesCurrent.nombre,
                    "ApPaterno": uberSeguroSto.datosPersonalesCurrent.apPaterno,
                    "ApMaterno": uberSeguroSto.datosPersonalesCurrent.apMaterno,
                    "FechaNacimiento": uberSeguroSto.datosPersonalesCurrent.fechaNacimiento,
                    "Sexo": sexCurrent,
                    "RFC": uberSeguroSto.datosPersonalesCurrent.rfc
                },
                "PagoElectronico": {
                    "medio": "WEB",
                    "TarjetaTipo": "Credito",
                    "PAN": values.numTarjeta.toString(),
                    "fechaVencimiento": fechaVencimiento,
                    "NombreTitular": values.nombre,
                    "PaternoTitular": values.apPaterno,
                    "MaternoTitular": values.apMaterno,
                    "MSI": 0,
                    "email": uberSeguroSto.datosPersonales.correo
                },
                "NumeroEmpleado": colaborador
            };
          }
          window.sessionStorage.setItem("uberSeguro",JSON.stringify(uberSeguroSto));
          PasarelaApi.emitir(params)
            .then(res => {
              window.sessionStorage.setItem("poliza",res.data.data['Numero-Poliza']);
              setLoading(false);
              props.history.push("/compra-exitosa");
            })
            .catch(error => {
              setLoading(false);
              getErrorDes(error, setTipoMensajeErr);
              setModalShow(true);
            });
        }
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      setUberSeguro(uberSeguroSto);
      setTipoPersona(uberSeguroSto.tipoPersona);
      if(uberSeguroSto.datosPersonales){
        var hoy = new Date();
        var cumpleanos = convertDDMMYYYYToDate(uberSeguroSto.datosPersonales.fechaNacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        uberSeguroSto.datosPersonales.edad = edad;
        setDatosPersonales(uberSeguroSto.datosPersonales);
      }
      if(uberSeguroSto.auto){
        setAuto(uberSeguroSto.auto);
      }
      if(uberSeguroSto.domicilio){
        setDatosDomicilio(uberSeguroSto.domicilio);
      }
      if(window.sessionStorage.getItem("type")){
        const typePlan = window.sessionStorage.getItem("type");
        if(window.sessionStorage.getItem(typePlan)){
          const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
          setCotizacion(cotizacionJson);
        }
      }
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Error
        open={modalShow}
        handleClose={() => setModalShow(false)}
        titulo={ERROR_TITULO_MSG}
        mensaje={tipoMensajeErr}
      />
      <Loading
        open={isLoading}
        handleClose={() => setLoading(false)}
      />
      <Formik
        validateOnChange={true}
        initialValues={{
          nombre: '',
          apPaterno: '',
          apMaterno: '',
          numTarjeta: '',
          fechaVencimiento: new Date(),
          cvv: '',
          tipoTarjeta: null,
          indemnizacion: false,
          siniestrado: false,
          avisoPrivacidad: false,
          condicionesGenerales: false,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          continuar(values, setSubmitting);
        }}
        validationSchema={validationForm}
      >
        {({
          values,
          submitForm,
          setFieldValue,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Form>
            <div className="body-estruct">
              <div className="container py-20x">
                <div className="row">
                  <div className="col-12 align-self-center">
                    <div className="tarjeta-form">

                      <div className="row">
                        <div className="col-12">
                          <div id="btn_regresar_dv" className="btn-back" onClick={props.back}>
                            <div>Regresar</div>
                          </div>
                        </div>
                        <div className="row justify-content-md-center">
                          <div className="col-md-12">
                            <div className="lbl-resumen">
                              <b></b>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-md-center">
                          <div className="col-md-10">
                            <div className="lbl-resumen">
                              El total que ser&aacute; cargado a tu tarjeta es de <b><FormatNumber number={cotizacion['Importes-Recibos'].primer_recibo.PrimaTotal} /></b>
                              <b className="font-600"></b> pesos por la compra
                              del paquete <b>{cotizacion.Vehiculo.Uso}</b>
                              <b
                                className="link font-600"
                                title="Ver detalle de coberturas"
                              ></b>{" "}
                              con la aseguradora <b className="font-600"> AXA</b>.
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="lbl-subtitle">
                              Por favor llena los siguientes datos.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row justify-content-center no-gutters">
                          <div className="col-12">
                            <div className="col-sm-12 col-md-12 col-lg-6 pasarelaTarjeta">
                              <div className="container preload">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="creditcard">
                                      <div className="front">
                                        <div className="ccsinglediv">
                                          <svg version="1.1" id="ccsingle" className="ccsinglesvg" xmlns="http://www.w3.org/2000/svg" width="482.51" height="374" viewBox="0 0 482.51 374"></svg>
                                        </div>
                                        <svg version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 750 471" className="pasarela" >
                                        <g id="Front">
                                          <g id="CardBackground">
                                            <g id="Page-1_1_">
                                              <g id="amex_1_">
                                                <path id="Rectangle-1_1_" className="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                C0,17.9,17.9,0,40,0z" />
                                              </g>
                                            </g>
                                            <path className="darkcolor greydark" d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z"/>
                                          </g>
                                          <text transform="matrix(1 0 0 1 60.106 295.0121)" id="svgnumber" className="st2 st3 st4" >
                                            {values.numTarjeta ? values.numTarjeta : '0123 4567 8910 1112'}
                                          </text>
                                          <text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" className="st2 st5 st6" >
                                            {values.nombre ? values.nombre + ' ' +
                                              (values.apPaterno ? values.apPaterno : '') + ' ' +
                                                (values.apMaterno ? values.apMaterno : '')
                                            : 'Name'}
                                          </text>
                                          <text transform="matrix(1 0 0 1 54.1074 389.8793)" className="st7 st5 st8">
                                            Nombre
                                          </text>
                                          <text transform="matrix(1 0 0 1 479.7754 388.8793)" className="st7 st5 st8">
                                            V&aacute;lida hasta
                                          </text>
                                          <text transform="matrix(1 0 0 1 65.1054 241.5)" className="st7 st5 st8">
                                            N&uacute;mero tarjeta
                                          </text>
                                          <g>
                                            <text transform="matrix(1 0 0 1 574.4219 433.8095)" id="svgexpire" className="st2 st5 st9">
                                              01/23
                                            </text>
                                            <text transform="matrix(1 0 0 1 479.3848 417.0097)" className="st2 st10 st11">
                                              VALID
                                            </text>
                                            <text transform="matrix(1 0 0 1 479.3848 435.6762)" className="st2 st10 st11" >
                                              THRU
                                            </text>
                                            <polygon className="st2" points="554.5,421 540.4,414.2 540.4,427.9     " />
                                          </g>
                                          <g id="cchip">
                                            <g>
                                              <path className="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                                              c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z"/>
                                            </g>
                                            <g>
                                              <g>
                                              <rect
                                              x="82"
                                              y="70"
                                              className="st12"
                                              width="1.5"
                                              height="60"
                                              />
                                              </g>
                                              <g>
                                              <rect
                                              x="167.4"
                                              y="70"
                                              className="st12"
                                              width="1.5"
                                              height="60"
                                              />
                                              </g>
                                              <g>
                                              <path
                                              className="st12"
                                              d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                                              c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                                              C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                                              c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                                              c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z"
                                              />
                                              </g>
                                              <g>
                                              <rect
                                              x="82.8"
                                              y="82.1"
                                              className="st12"
                                              width="25.8"
                                              height="1.5"
                                              />
                                              </g>
                                              <g>
                                              <rect
                                              x="82.8"
                                              y="117.9"
                                              className="st12"
                                              width="26.1"
                                              height="1.5"
                                              />
                                              </g>
                                              <g>
                                              <rect
                                              x="142.4"
                                              y="82.1"
                                              className="st12"
                                              width="25.8"
                                              height="1.5"
                                              />
                                              </g>
                                              <g>
                                              <rect
                                              x="142"
                                              y="117.9"
                                              className="st12"
                                              width="26.2"
                                              height="1.5"
                                              />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                          <g id="Back"></g>
                                        </svg>
                                      </div>
                                      <div className="back">
                                        <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 750 471" className="pasarela" >
                                          <g id="Front">
                                            <line
                                            className="st0"
                                            x1="35.3"
                                            y1="10.4"
                                            x2="36.7"
                                            y2="11"
                                            />
                                          </g>
                                          <g id="Back">
                                            <g id="Page-1_2_">
                                              <g id="amex_2_">
                                                <path
                                                id="Rectangle-1_2_"
                                                className="darkcolor greydark"
                                                d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                C0,17.9,17.9,0,40,0z"
                                                />
                                              </g>
                                            </g>
                                            <rect
                                            y="61.6"
                                            className="st2"
                                            width="750"
                                            height="78"
                                            />
                                            <g>
                                              <path
                                              className="st3"
                                              d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                                              C707.1,246.4,704.4,249.1,701.1,249.1z"
                                              />
                                              <rect
                                              x="42.9"
                                              y="198.6"
                                              className="st4"
                                              width="664.1"
                                              height="10.5"
                                              />
                                              <rect
                                              x="42.9"
                                              y="224.5"
                                              className="st4"
                                              width="664.1"
                                              height="10.5"
                                              />
                                              <path
                                              className="st5"
                                              d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
                                              />
                                            </g>
                                            <text
                                            transform="matrix(1 0 0 1 621.999 227.2734)"
                                            id="svgsecurity"
                                            className="st6 st7"
                                            >
                                              {values.cvv ? values.cvv : '985'}
                                            </text>
                                            <g className="st8">
                                              <text
                                                transform="matrix(1 0 0 1 518.083 280.0879)"
                                                className="st9 st6 st10"
                                                >
                                                C&oacute;digo Seguridad
                                              </text>
                                            </g>
                                            <rect x="58.1" y="378.6" className="st11" width="375.5" height="13.5" />
                                            <rect x="58.1" y="405.6" className="st11" width="421.7" height="13.5" />
                                            <text transform="matrix(1 0 0 1 59.5073 228.6099)" id="svgnameback" className="st12 st13">                                              
                                              {values.nombre ? values.nombre + ' ' +
                                              (values.apPaterno ? values.apPaterno : '') + ' ' +
                                              (values.apMaterno ? values.apMaterno : '')
                                              : 'Name'}
                                            </text>
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 w-100">
                              <div className="row justify-content-center">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <label className="lb-form">
                              Nombre en Tarjeta
                              </label>
                              <CustomTextField id="nombre" name="nombre" type="text" placeholder="Nombres"/>
                              </div>
                              </div>
                              <div className="row justify-content-center">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                              <CustomTextField id="apPaterno" name="apPaterno" type="text" placeholder="Apellido Paterno"/>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                              <CustomTextField id="apMaterno" name="apMaterno" type="text" placeholder="Apellido Materno"/>
                              </div>
                              </div>
                              <div className="row justify-content-center">
                              <div className="col-12">
                              <CustomTextField id="numTarjeta" name="numTarjeta" type="number" placeholder="Número de tarjeta"
                              onChange={(event) => handleChangeNumTarjeta(event, setFieldValue)}/>
                              </div>
                              </div>
                              <div className="row justify-content-center">
                              <div className="col-6">
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <CustomDatePicker
                                name="fechaVencimiento"
                                variant="inline"
                                autoOk={true}
                                disablePast
                                disableToolbar
                                format="MM/yyyy"
                                views={["year", "month"]}
                                onChange={(val) => {
                                  setFieldValue("fechaVencimiento", val);
                                }}
                              />
                              </MuiPickersUtilsProvider>
                              </div>
                              <div className="col-6">
                              <CustomTextField id="cvv" name="cvv" type="number" placeholder="Código (CVV)" maxLength="4"
                              onChange={(event) => handleChangeCVV(event, setFieldValue)}
                              onBlur={() => handleBlurCVV()}/>
                              </div>
                              </div>
                              <div className="row justify-content-center">
                                <CustomRadioGroup row name="tipoTarjeta">
                                  <div className="col-6">
                                    <CustomRadio name="tipoTarjeta" type="radio"
                                      value="credito" label="Crédito" />
                                  </div>
                                  <div className="col-6">
                                    <CustomRadio name="tipoTarjeta" type="radio"
                                      value="debito" label="Débito" />
                                  </div>
                                </CustomRadioGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-center no-gutters">
                        <div className="col-12 col-lg-12">
                          <div className="row justify-content-center meses-container">
                            <div className="col-12 py-20x">
                              <div className="row-sio">
                                <div className="cell-sio">
                                  <CustomCheckbox name="indemnizacion" />
                                  <label className="text-middle pl-10x font-terminos">
                                    Mi veh&iacute;culo no tiene alg&uacute;n siniestro por reclamar y no ha sido indemnizado por p&eacute;rdida total
                                  </label>
                                </div>
                              </div>
                                <div className="row-sio">
                                  <div className="cell-sio">
                                    <CustomCheckbox name="siniestrado" />
                                    <label className="text-middle pl-10x font-terminos">
                                      Mi veh&iacute;culo no se encuentra chocado o
                                      siniestrado de alguna forma.
                                    </label>
                                  </div>
                                </div>
                                <div className="row-sio">
                                  <div className="cell-sio">
                                    <CustomCheckbox name="avisoPrivacidad" />
                                    <label className="text-middle pl-10x font-terminos">
                                      He le&iacute;do y acepto el&nbsp;
                                      <a href="https://storage.googleapis.com/coche-seguro-inter/AP_FIRMA_CLIENTES_VF.pdf"
                                        target="_blank" alt="" rel="noreferrer" className="a-color">
                                        Aviso de privacidad.
                                      </a>
                                    </label>
                                  </div>
                                </div>
                                <div className="row-sio">
                                  <div className="cell-sio">
                                    <CustomCheckbox name="condicionesGenerales" />
                                    <label className="text-middle pl-10x font-terminos">
                                      Estoy de acuerdo con las&nbsp;
                                      <a href="https://storage.googleapis.com/coche-seguro-inter/Terminosycondicionescocheseguro.pdf"
                                        target="_blank" alt="" rel="noreferrer" className="a-color">
                                        Condiciones Generales y T&eacute;rminos de Uso.
                                      </a>
                                    </label>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div> 

                      <div className="col-12 mt-20x">
                          <div className="row justify-content-md-center">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center">
                                  <img className="w-150x" src={logoDigiCert} alt=""/>    
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                  <label className="digi-cert-text">
                                    Por seguridad el portal de inter.mx no almacena n&uacute;meros de tarjeta de cr&eacute;dito, la responsabilidad de la domiciliaci&oacute;n en formas de pago diferidas queda en manos de cada aseguradora.
                                  </label>                                        
                              </div>
                          </div>
                      </div>

                    </div>
                    <div className="col-md-6 offset-md-3">
                      <Button variant="contained" name="continuar" id="btn_dp_continuar" className="w-100 form-button button-continuar"
                        onClick={submitForm} disabled={isSubmitting}>
                        CONTINUAR
                      </Button >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
