import React, { useEffect, useState } from 'react';
import './Inicio.css';

import { CMB_MENOS_UNO, CP_INVALIDO, ERROR_MSG, ERROR_TITULO_MSG, MAX_LENGTH_CP, MIN_LETRAS_DES_AUTO } from '../Utils/Constantes';
import { Form, Formik } from 'formik';

import CustomTextField from "../Forms/CustomTextField";
import CustomRadioGroup from '../Forms/CustomRadioGroup';
import CustomRadio from '../Forms/CustomRadio';
import { maxLength } from '../Utils/Utils';
import CustomSelect from '../Forms/CustomSelect';
import { Autocomplete, Checkbox, Dialog, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { validationFisicaInicioForm, validationMoralForm } from './ValidationsFormYup';
import Button from '@mui/material/Button';
import InicioApi from '../Api/Inicio';
import AppSpot from '../Api/AppSpot';
import Error from '../Estructura/Modal/Error';
import CustomErrorMessage from '../Forms/CustomErrorMessage';
import FormDialogNoEncuentroAuto from '../Inicio/FormDialogNoEncuentroAuto';


// const plataformasArray = [
//   {id:1,name:'Avant'},
//   {id:2,name:'Beat'},
//   {id:3,name:'Cabify'},
//   {id:4,name:'Didi'},
//   {id:5,name:'Easy Taxi'},
//   {id:6,name:'Uber'},
//   {id:7,name:'Yaxi'},
// ];

export default function RegistroForm(props) {
  const [openFormModal, setOpenFormModal] = React.useState(false);
  const [modalShow, setModalShow] = useState(props.err ? true : false);
  const [modelos, setModelos] = useState([]);
  const [descripcionAutos, setDescripcionAutos] = useState([]);
  const [registroForm, setRegistroForm] = useState({
    tipoPersona: '1',
    nombre: null,
    correo: null,
    telefono: null,
    genero: null,
    edad: null,
    cp: null,
    //plataformas: null,
    modelo: null,
    estado: null,
    municipio: null,
  });
  const handleClickClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenFormModal(false);
    }
  };
  const handleClickModal = () => {
    handleClickOpen();
  };
  const handleClickOpen = () => {
    setOpenFormModal(true);
  };
  const [auto, setAuto] = useState(null);
  const [tipoPersona, setTipoPersona] = useState('1');

  /* funciones para persona */
  const handleOnChangeTipoPersona = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    setTipoPersona(value);
    setFieldValue(name, value);
  };
  /*terminan funciones para persona */

  /* funciones para cp */
  const handleChangeValidateCP = (e, setFieldValue, setFieldError) => {
    setRegistroForm((current) => ({
      ...current,
      estado: "",
      municipio: ""
    }));
    const { target: { value, name }, } = e;
    const val = maxLength(value, MAX_LENGTH_CP);
    setFieldValue(name, val);
    if (val.length === MAX_LENGTH_CP) {
      InicioApi.CodigoPostalvalidacion(val)
        .then(res => {
          if (res.data.length > 0) {
            setRegistroForm((current) => ({
              ...current,
              estado: res.data[0].c1,
              municipio: res.data[0].c2
            }));
          } else {
            setFieldValue(name, '');
            setFieldError(name, CP_INVALIDO);
          }
        })
        .catch(error => {
          setFieldValue(name, '');
          setFieldError(name, CP_INVALIDO);
        });
    }
  };
  /*terminan funciones para cp */

  const handleMaxLength = (e, setFieldValue, field, max, error) => {
    let val = e.target.value;
    val = maxLength(val, max);
    setFieldValue(field, val);
  };

  /* funciones para combo Genero */
  const handleOnChangeGenero = (e, setFieldValue) => {
    let val = e.target.value;
    setFieldValue("genero", val);
  };
  /*terminan funciones para combo Genero */

  /* funciones para combo plataformas */
  // const handleOnChangePlataforma = (event, setFieldValue) => {
  //   const { target: { value, name }, } = event;
  //   const multipleValues = [];
  //   for (let i = 0, l = value.length; i < l; i += 1) {
  //     multipleValues.push(parseInt(value[i]));
  //   }
  //   setFieldValue(name, multipleValues);
  // };

  // const renderValues = (selected, plataformas) => {
  //   let platSelNames = [];
  //   selected.forEach(function logArrayElements(element) {
  //     const platSel = plataformasArray.find((plat) => plat.id === element);
  //     if(platSel){
  //       platSelNames.push(platSel.name);
  //     }
  //   });
  //   if(selected.length === 1 && (selected.findIndex(id => id === -1) === 0)){
  //     platSelNames.push('Seleccionar');
  //   }
  //   return platSelNames.join(', ');
  // };
  /* termina funciones para combo plataformas */
  /* funciones para combo modelo */
  useEffect(() => {
    let fecha = new Date();
    let modelo = (fecha.getFullYear() + 1);
    let modelosGenerados = [];
    for (let i = 0; i <= (modelo - 2011); i++) {
      modelosGenerados.push({ "c1": (fecha.getFullYear() + 1) });
      fecha.setFullYear(fecha.getFullYear() - 1);
    }
    setModelos(modelosGenerados);
  }, []);

  const handleChangeModelo = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    setFieldValue(name, value);
    resetValoresAutos(setFieldValue);
  };
  const resetValoresAutos = (setFieldValue) => {
    setAuto(null);
    setDescripcionAutos([]);
    setFieldValue("descripcion", '');
    setFieldValue("descripcionDes", '');
  };
  /* termina funciones para combo modelo */

  /* funciones para seleccionar auto */
  const handleBusquedaAuto = (e, setFieldValue, values) => {
    const { target: { value, name }, } = e;
    let model = 0;
    setFieldValue(name, value);
    if (value.length >= MIN_LETRAS_DES_AUTO) {
      if (values.modelo > -1) {
        model = values.modelo;
      } else {
        let fecha = new Date();
        model = (fecha.getFullYear());
        console.log(model);
      }
      AppSpot.AutosBuscador(value, model)
        .then(res => {
          setDescripcionAutos(res.data.data);
        })
        .catch(error => {
          console.log(model);
        });
    }
  };
  // const onInputChangeAutoComplete = (event, newValue) => {
  //   console.log(event);
  //   console.log(newValue);
  // };
  const handleAutoSeleccionado = (event, newValue, setFieldValue) => {
    setAuto(newValue);
    if (newValue !== null) {
      setFieldValue("descripcionDes", newValue.description);
    }
  };
  /* terminan funciones para seleccionar auto */

  const continuar = (values, setSubmitting) => {
    const datosPersonales = {
      nombre: values.nombre,
      genero: values.genero,
      correo: values.correo,
      telefono: values.telefono,
      edad: values.edad,
    }
    const domicilio = {
      estado: registroForm.estado,
      municipio: registroForm.municipio,
      cp: values.cp,
    }
    const cocheSeguro = {
      tipoPersona: (tipoPersona === '1' ? 1 : 2),
      modelo: values.modelo,
      //plataformas: values.plataformas.filter(id => id !== -1),
      auto: auto,
      datosPersonales: datosPersonales,
      domicilio: domicilio
    };
    //localStorage.setItem("uberSeguro",JSON.stringify(cocheSeguro));
    window.sessionStorage.setItem("uberSeguro", JSON.stringify(cocheSeguro));
    setSubmitting(false);
    window.dataLayer.push({
      'event': "formStep0Submit",
      'eventCategory': 'Formulario.Seguro.AutoApp',
      'eventAction': 'Step_0',
      'Nombre': values.nombre,
      'Correo': values.correo,
      'teléfono': values.telefono
    });
    window.dataLayer.push({
      "event" : "cocheapp_seguro_cotizar",      //Static data,
      "boton_persona": (tipoPersona === '1' ? 'Fisica' : 'Moral'),
      "sexo": (values.genero === '0' ? 'Hombre' : 'Mujer'),
      "cp": values.cp,
      "edad": values.edad,
      "cotización": "",
      "anio": values.modelo,
      "modelo": auto.description,
      "boton_contacto": "Encontrar el mejor precio"
    });
    props.history.push("/cotizacion");
  };

  return (
    <>
      <Error
        open={modalShow}
        handleClose={() => setModalShow(false)}
        titulo={ERROR_TITULO_MSG}
        mensaje={ERROR_MSG}
      />
      <Dialog open={openFormModal} onClose={(event, reason) => handleClickClose(event, reason)} disableEscapeKeyDown={true}
        maxWidth="sm" fullWidth={false}>
        <>
          <FormDialogNoEncuentroAuto handleClose={handleClickClose} />
        </>
      </Dialog>
      <Formik validateOnChange={true}
        initialValues={{
          tipoPersona: (registroForm.tipoPersona != null ? registroForm.tipoPersona : ''),
          nombre: (registroForm.nombre != null ? registroForm.nombre : ''),
          correo: (registroForm.correo != null ? registroForm.correo : ''),
          telefono: (registroForm.telefono != null ? registroForm.telefono : ''),
          genero: (registroForm.genero != null ? registroForm.genero : -1),
          edad: (registroForm.edad != null ? registroForm.edad : ''),
          cp: (registroForm.cp != null ? registroForm.cp : ''),
          //plataformas: [-1],
          modelo: (registroForm.modelo != null ? registroForm.modelo : -1),
          descripcion: '',
          descripcionDes: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          continuar(values, setSubmitting);
        }}
        validationSchema={tipoPersona === '1' ? validationFisicaInicioForm : validationMoralForm}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
          <Form>
            <div className="table-sio w-100">
              <div className="row-sio">
                <div className="cell-sio text-center">
                  <h1 className="titulo my-0x">COTIZAR MI SEGURO</h1>
                </div>
              </div>
              <div className="row-sio">
                <div className="cell-sio text-center">
                  <h6 className="mejor-servicio my-0x">TUS DATOS NOS AYUDAN A BRINDARTE UN MEJOR SERVICIO</h6>
                </div>
              </div>
              <div className="row-sio">
                <div className="cell-sio tipo-persona-text">
                  <CustomRadioGroup row name="tipoPersona"
                    onChange={(event) => handleOnChangeTipoPersona(event, setFieldValue)}>
                    <CustomRadio name="tipoPersona" type="radio"
                      value='1' label="Persona Física" />
                    <CustomRadio name="tipoPersona" type="radio"
                      value='2' label="Persona Moral" />
                  </CustomRadioGroup>
                </div>
              </div>
              <div className="row-sio">
                <div className="cell-sio">
                  <CustomTextField id="nombre" name="nombre" type="text" placeholder={tipoPersona === '1' ? "Nombre" : "Razón Social"} />
                </div>
              </div>
              <div className="row-sio">
                <div id="correo" className="cell-sio">
                  {/* [disabled]="prospecto.logeado"  */}
                  <div className="d-flex justify-content-start align-items-center">
                    <CustomTextField id="correo" name="correo" type="text" placeholder={"Correo electrónico" + (tipoPersona === '1' ? "" : " del contratante")} />
                  </div>
                </div>
              </div>
              <div className="row-sio" >
                <div className="cell-sio">
                  <div className="table-sio w-100 vertical-middle">
                    <div className="cell-sio pr-5x">
                      <div className="d-flex justify-content-start align-items-center">
                        <CustomTextField id="telefono" name="telefono" type="number"
                          onChange={(event) => handleMaxLength(event, setFieldValue, "telefono", 10)}
                          placeholder={"Teléfono" + (tipoPersona === '1' ? "" : " del contratante")} />
                      </div>
                    </div>
                    <div className="cell-sio pl-5x">
                      <div className="d-flex justify-content-end align-items-center">
                        <CustomTextField id="cp" name="cp" type="number" placeholder="CP"
                          onChange={(event) => handleChangeValidateCP(event, setFieldValue, setFieldError)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {values.tipoPersona === '1' ?
                <div className="row-sio" >
                  <div className="cell-sio">
                    <div className="table-sio w-100 vertical-middle">
                      <div className="cell-sio pr-5x">
                        <div className="d-flex justify-content-start align-items-center">
                          <CustomSelect id="genero" name="genero"
                            onChange={(event) => handleOnChangeGenero(event, setFieldValue)}>
                            <MenuItem value={CMB_MENOS_UNO}>Sexo</MenuItem>
                            <MenuItem value={0}>Hombre</MenuItem>
                            <MenuItem value={1}>Mujer</MenuItem>
                          </CustomSelect>
                        </div>
                      </div>
                      <div className="cell-sio pl-5x">
                        <div className="d-flex justify-content-end align-items-center">
                          <CustomTextField id="edad" name="edad" type="number" placeholder="Edad" onChange={(event) => handleMaxLength(event, setFieldValue, "edad", 2)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <></>}

              {/* <div className="row-sio">
              <div className="cell-sio text-center">
                <div className="selecciona-plataforma-text my-0x">Selecciona la plataforma de la que eres conductor</div>
              </div>
            </div>

            <div className="row-sio">
              <div className="cell-sio">
                <Select
                  id="plataformas" name="plataformas"
                  multiple
                  value={values.plataformas}
                  renderValue={(sel) => renderValues(sel, values.plataformas)}
                  onChange={(event) => handleOnChangePlataforma(event, setFieldValue)}>
                  <MenuItem value={CMB_MENOS_UNO}>Seleccionar</MenuItem>
                    {plataformasArray.map((plataforma) => (
                      <MenuItem key={plataforma.id} value={plataforma.id}>
                        <Checkbox checked={(values.plataformas.findIndex((plat) => plat === plataforma.id)) >= 0 && plataforma.id !== -1} />
                        <ListItemText primary={plataforma.name} />
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
            <div className="row-sio">
              <div className="cell-sio text-left seleccionar-opciones-text">
                *Puedes seleccionar m&aacute;s de una opci&oacute;n.
              </div>
            </div> */}

              <div className="row-sio">
                <div className="cell-sio">
                  <div className="table-sio w-100">
                    <div className="cell-sio w-35 text-center text-middle">
                      <div className="modelo-descripcion">Modelo y Descripción</div>
                    </div>
                    <div className="cell-sio w-30">
                      <CustomSelect id="modelo" name="modelo"
                        onChange={(event) => handleChangeModelo(event, setFieldValue)}>
                        <MenuItem value={-1}>Seleccionar</MenuItem>
                        {modelos.map((modelo) => (
                          <MenuItem key={modelo.c1} value={modelo.c1}>
                            {modelo.c1}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-sio">
                <div className="cell-sio pt-20x">
                  <Autocomplete clearText="Borrar"
                    options={descripcionAutos}
                    getOptionLabel={(option) => option.description}
                    id="descripcionDes"
                    name="descripcionDes"
                    noOptionsText="Escriba de nuevo para buscar"
                    onChange={(event, newValue) => {
                      handleAutoSeleccionado(event, newValue, setFieldValue);
                    }}
                    // inputValue={values.descripcionDes}
                    // onInputChange={(event, newInputValue) => {
                    //   onInputChangeAutoComplete(event, newInputValue);
                    // }}
                    renderInput={(params) => (
                      <TextField {...params} id="descripcionDes" name="descripcionDes" placeholder="Ejemplo: Mazda 3, Jetta, Aveo, etc." variant="standard"
                        value={values.descripcionDes} onChange={(event) => handleBusquedaAuto(event, setFieldValue, values)} />
                    )}
                  />
                  <CustomErrorMessage name={"descripcionDes"} />
                </div>
              </div>

              {auto != null ?
                <div className='table-sio w-100 h-70x'>
                  <div className='row-sio w-100'>
                    <div className='cell-sio pl-20x align-middle'>
                      <div className='table-sio'>
                        <div className='row-sio'>
                          <div className='cell-sio'>
                            <div className='text-elipsis-two-line' title={auto.description}>
                              <b className="font-600">{auto.model + ' ' + auto.description}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className='table-sio w-100 h-70x'>
                  <div className="row-sio">
                    <div className="cell-sio text-center no-auto-text">
                      <Button onClick={handleClickModal}> No encuentro mi auto</Button>
                    </div>
                  </div>
                  <div className="row-sio">
                    <div className="cell-sio text-center cotiza-text">
                      ¿Ya tienes seguro?, Cotiza contrata y decide cuando quieres que inicie tu vigencia.
                    </div>
                  </div>
                </div>
              }
              <div className="row-sio">
                <div className="cell-sio text-center pt-20x">
                  <Button variant="contained" name="cotizaciones" id="btnCotizaciones" className="w-100 form-button"
                    onClick={submitForm} disabled={isSubmitting}>
                    ENCONTRAR EL MEJOR PRECIO
                  </Button >
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

    </>
  );
}
