import { DatePicker } from "@material-ui/pickers";
import { useField } from "formik";
import React from "react";

export default function CustomDatePicker(props) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      helperText={errorText}
      error={!!errorText}
    />
  );
}
