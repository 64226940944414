import './Modal.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    color: "#000037",
  },
  titulo: {
      color: "#000037"
  },
  content: {
      backgroundColor:"#fff",
      color: "#000037"
  },
  btn: {
    color: "#000037",
    backgroundColor: "#e0e0e0",
  },
}));
export default function Error(props) {
  const classes = useStyles();

  return (
    <>
      <Modal
        size="lg"
        open={props.open}
        onClose={props.handleClose}>        
        <div className="modal-contenedor">
            <div className="modal-error">
              <div className="close-button">
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="titulo">
                <p className="titulo">{props.titulo}</p>
              </div>
              <div className="mensaje">
                <p className="descripcion">{props.mensaje}</p>
              </div>
            </div>
          </div>
      </Modal>
    </>
  );
}