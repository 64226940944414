import React, { useEffect, useState } from 'react';
import './DatosPersonales.css';

import { CMB_MENOS_UNO, CP_INVALIDO, ERROR_MSG, ERROR_TITULO_MSG, MAX_LENGTH_CP, MAX_LENGTH_RFC, MAX_LENGTH_RFC_M, MAYOR_EDAD } from '../Utils/Constantes';
import { Form, Formik } from 'formik';
import { validationFisicaForm, validationMoralForm } from './ValidationsFormYup';
import CustomTextField from '../Forms/CustomTextField';
import CustomSelect from '../Forms/CustomSelect';
import { MenuItem } from '@mui/material';
import { calcularEdad, convertDDMMYYYYToDate, formatDDMMYYYY, maxLength, validaMayorEdad } from '../Utils/Utils';
import InicioApi from '../Api/Inicio';
import DatosPersonalesApi from '../Api/DatosPersonales';
import Button from '@mui/material/Button';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import CustomDatePicker from '../Forms/CustomDatePicker';
import Error from '../Estructura/Modal/Error';
import Loading from '../Estructura/Modal/Loading';
import { recotizar } from '../Utils/Recotizacion';

var errorTitulo = ERROR_TITULO_MSG;
var errorMsg = ERROR_MSG;
export default function RegistroForm(props) {
  const [uberSeguro, setUberSeguro] = useState(null);
  const [tipoPersona, setTipoPersona] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isDataReady, setDataReady] = useState(false);
  const [datosPersonales, setDatosPersonales] = useState({
    nombre: null,
    apPaterno: null,
    apMaterno: null,
    fechaNacimiento: null,
    genero: null,
    rfc: null,
    telefono: null,
    correo: null,
  });
  const [domicilio, setDomicilio] = useState({
    estado: null,
    municipio: null,
    munId: null,
    calle: null,
    numExt: null,
    numInt: null,
    cp: null,
    colonia: null,
    ciudad: null,
  });
  const [colonias, setColonias] = useState([]);

  useEffect(() => {
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      setUberSeguro(uberSeguroSto);
      setTipoPersona(uberSeguroSto.tipoPersona);
      if(uberSeguroSto.datosPersonales){
        setDatosPersonales(uberSeguroSto.datosPersonales);
      }
      if(uberSeguroSto.domicilio){
        setDomicilio(uberSeguroSto.domicilio);
        if(uberSeguroSto.domicilio.cp){          
          handleColoniasByCP(uberSeguroSto.domicilio.cp);
        }
      }
      setLoading(false);
    }
  }, []);

  /* funciones para combo Genero */
  const handleOnChangeGenero = (e, setFieldValue) => {
    let val = e.target.value;
    setFieldValue("genero", val);
  };
  /*terminan funciones para combo Genero */
  /* funciones para cp */
  const handleChangeValidateCP = (e, setFieldValue, setFieldError) => {
    setDomicilio((current) => ({...current, 
      estado: "",
      municipio: ""}));
    const { target: { value, name }, } = e;
    const val = maxLength(value, MAX_LENGTH_CP);
    setFieldValue(name, val);
    if(val.length === MAX_LENGTH_CP){
      InicioApi.CodigoPostalvalidacion(val)
        .then(res => {
          if(res.data.length > 0){
            setDomicilio((current) => ({...current,
              estado: res.data[0].c1,
              municipio: res.data[0].c2,
              cp: val
            }));
            handleColoniasByCP(val);
          } else {
            setFieldValue(name, '');
            setFieldError(name, CP_INVALIDO);
          }
        })
        .catch(error => {
          setFieldValue(name, '');
          setFieldError(name, CP_INVALIDO);
          errorTitulo = ERROR_TITULO_MSG;
          errorMsg = ERROR_MSG;
          setModalShow(true);
        });
      }
    };
  const handleColoniasByCP = (cp) => {
    DatosPersonalesApi.Sio4CodigoPostalAPI(cp)
      .then(res => {
        if(res.data.length > 0){
          setColonias(res.data[0]);
          setDomicilio((current) => ({...current,
            colonia: res.data[0][0].c2,
            ciudad: res.data[1][0].c2,
            munId: res.data[1][0].c3,
          }));
          if(res.data[0][0].c3){
            setDomicilio((current) => ({...current,
              estadoId: res.data[0][0].c3,
            }));
          };
        }
        setDataReady(true);
      })
      .catch(error => {
        console.log(error);
        errorTitulo = ERROR_TITULO_MSG;
        errorMsg = ERROR_MSG;
        setModalShow(true);
      });
    };
  /*terminan funciones para cp */
  
  const handleOnChangeColonia = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    setFieldValue(name, value);
  };

  const handleOnChangeRFC = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    const val = maxLength(value, 
      (tipoPersona === 1 ? MAX_LENGTH_RFC : MAX_LENGTH_RFC_M)).replace(/[^a-zA-Z0-9]/g, "");
    setFieldValue(name, val);
  };

  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError, values) => {
    if(validaMayorEdad(fecha)){
      if(tipoPersona === 1){
        /** prende modal de recotiazcion*/
        if(calcularEdad(fecha) !== Number(uberSeguro.datosPersonales.edad)){
          errorTitulo = "Cambio de fecha";
          errorMsg = "La edad del conductor habitual es distinta a la del contratante por lo que la prima puede cambiar.";
          /** prende bandera recotizo*/
          window.sessionStorage.setItem("recotizo",true);
          setModalShow(true);
        }
        setLoading(true);
        /** hace la recotiazcion*/
        handleRecotizar(setFieldValue,"fechaNacimiento",fecha, values);
      } else {
        setFieldValue("fechaNacimiento",fecha);
      }
    } else {
      setFieldError("fechaNacimiento",MAYOR_EDAD);
    }
  };

  const handleRecotizar = (setFieldValue, fieldName, fieldValue, values) => {    
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      if(window.sessionStorage.getItem("type")){
        const typePlan = JSON.parse(window.sessionStorage.getItem(window.sessionStorage.getItem("type")));
        recotizar(uberSeguroSto, typePlan, setLoading, setFieldValue, fieldName, fieldValue, values);
      }
    }
  };
  

  const continuar = (values, setSubmitting) => {
    let currentEdad = values.edad;
    if( uberSeguro.datosPersonales != null && uberSeguro.datosPersonales.edad){
      currentEdad = uberSeguro.datosPersonales.edad;
    }
    const datosPersonalesCurrent = {
      nombre: values.nombre,
      apPaterno: values.apPaterno,
      apMaterno: values.apMaterno,
      fechaNacimiento: formatDDMMYYYY(values.fechaNacimiento),
      genero: (values.genero != null ? values.genero: uberSeguro.datosPersonales.genero),
      correo: values.correo,
      telefono: values.telefono,
      edad: currentEdad,
      rfc: values.rfc,
    }
    const domicilioCurrent = {
      estado: uberSeguro.domicilio.estado,
      municipio: uberSeguro.domicilio.municipio,
      calle: values.calle,
      cp: values.cp,
      colonia: values.colonia,
      ciudad: values.ciudad,
      numExt: values.numExt,
      numInt: values.numInt,
      munId: domicilio.munId,
    }
    const uberSeguroSto = {
      tipoPersona: uberSeguro.tipoPersona,
      modelo: uberSeguro.modelo,
      plataformas: uberSeguro.plataformas,
      auto: uberSeguro.auto,
      datosPersonales: datosPersonalesCurrent,
      domicilio: domicilioCurrent
    };
    window.sessionStorage.setItem("uberSeguro",JSON.stringify(uberSeguroSto));
    const typePlan = window.sessionStorage.getItem("type");
    const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
    setSubmitting(false);
    window.dataLayer.push({
      'event': 'formStep2Submit',
      'eventCategory': 'Formulario.Seguro.AutoApp',
      'eventAction': 'Step2',
      'eventLabel': 'Datos Contratante',
      'name': values.nombre,
      'mail': values.correo,
      'last_name': values.apPaterno,
      'codigo_postal': values.cp
    });
    window.dataLayer.push({
      "event" : "cocheapp_seguro_datos_personales",      //Static data,
      "fecha_nacimiento": formatDDMMYYYY(values.fechaNacimiento),
      "sexo": (values.genero != null ? values.genero: uberSeguro.datosPersonales.genero),
      "cp": values.cp,
      "cotizacion": cotizacionJson.SolicitudID,
      "boton_datos_personales": "Continuar",
    });
    props.history.push("/datos-vehicle");
  };

  return (
    <>
    <Error
      open={modalShow}
      handleClose={() => setModalShow(false)}
      titulo={errorTitulo}
      mensaje={errorMsg}
    />
    <Loading
      open={isLoading}
      handleClose={() => setLoading(false)}
    />
    {isDataReady ? 
    <Formik validateOnChange={true}
      initialValues={{
        nombre: (datosPersonales.nombre != null ? datosPersonales.nombre: ''),
        apPaterno: (datosPersonales.apPaterno != null ? datosPersonales.apPaterno: ''),
        apMaterno: (datosPersonales.apMaterno != null ? datosPersonales.apMaterno: ''),
        fechaNacimiento: (datosPersonales.fechaNacimiento != null ? convertDDMMYYYYToDate(datosPersonales.fechaNacimiento) : new Date()),
        genero: (datosPersonales.genero != null ? datosPersonales.genero: ''),
        rfc: (datosPersonales.rfc != null ? datosPersonales.rfc: ''),
        calle: (domicilio.calle != null ? domicilio.calle: ''),
        numExt: (domicilio.numExt != null ? domicilio.numExt: ''),
        numInt: (domicilio.numInt != null ? domicilio.numInt: ''),
        cp: (domicilio.cp != null ? domicilio.cp: ''),
        colonia: (domicilio.colonia != null ? domicilio.colonia: ''),
        ciudad: (domicilio.ciudad != null ? domicilio.ciudad: ''),
        telefono: (datosPersonales.telefono != null ? datosPersonales.telefono: ''),
        correo: (datosPersonales.correo != null ? datosPersonales.correo: ''),
      }}
      onSubmit={(values, { setSubmitting }) => {
      setSubmitting(true);
      continuar(values, setSubmitting);
      }}
      validationSchema={tipoPersona === 1 ? validationFisicaForm : validationMoralForm}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError}) => (
          <Form>
            <div className="col-12">
              <div className="row">
                <div className="col align-self-center">
                  <div className="tarjeta-form">
                    {tipoPersona === 1 &&
                      <h3 className="lbl-titulo">Datos personales del Contratante</h3>
                    }
                    {tipoPersona === 2 &&
                      <h3 className="lbl-titulo">Datos de la Empresa</h3>
                    }
                    <div className="row">
                      {tipoPersona === 1 &&
                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x">
                          <label className="lb-form">Nombres(s)<span className="red">*</span></label>
                          <CustomTextField id="nombre" name="nombre" type="text" placeholder=""/>
                        </div>
                      }
                      {tipoPersona === 1 &&
                        <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                          <label className="lb-form">Apellido Paterno<span className="red">*</span></label>
                          <CustomTextField id="apPaterno" name="apPaterno" type="text" placeholder=""/>
                        </div>
                      }
                      {tipoPersona === 1 &&
                        <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                          <label className="lb-form">Apellido Materno</label>
                          <CustomTextField id="apMaterno" name="apMaterno" type="text" placeholder=""/>
                        </div>
                      }
                      {tipoPersona === 1 &&
                        <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                          <label className="lb-form">Fecha de Nacimiento (dd/mm/aaaa)<span className="red">*</span></label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <CustomDatePicker
                                name="fechaNacimiento"
                                variant="inline"
                                autoOk={true}
                                disableFuture
                                format="dd/MM/yyyy"
                                onChange={(newValue) => handleChangeFechaNacimiento(newValue, setFieldValue, setFieldError, values)}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      }
                      {tipoPersona === 1 &&
                        <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                          <label className="lb-form">Sexo<span className="red">*</span></label>
                          <CustomSelect id="genero" name="genero" disabled
                            onChange={(event) => handleOnChangeGenero(event, setFieldValue)}>
                            <MenuItem value={CMB_MENOS_UNO}>Sexo</MenuItem>
                            <MenuItem value={0}>Hombre</MenuItem>
                            <MenuItem value={1}>Mujer</MenuItem>
                          </CustomSelect>
                        </div>
                      }
                      {tipoPersona === 2 &&
                        <div className="col-12 col-md-12 col-xl-12 px-xs-12x">
                          <label className="lb-form">Raz&oacute;n Social<span className="red">*</span></label>
                          <CustomTextField id="nombre" name="nombre" type="text" placeholder=""/>
                        </div>
                      }
                      <div className="col-12 col-md-12 col-xl-12 px-xs-12x">
                        <label className="lb-form">RFC<span className="red">*</span></label>
                        <CustomTextField id="rfc" name="rfc" type="text" placeholder=""
                          onChange={(event) => handleOnChangeRFC(event, setFieldValue)}/>
                      </div>
                      <h3 className="lbl-titulo">Domicilio</h3>
                      <div className="col-12 col-md-12 col-xl-12 px-xs-12x">
                        <label className="lb-form">Calle<span className="red">*</span></label>
                        <CustomTextField id="calle" name="calle" type="text" placeholder=""/>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4 px-xs-4x">
                        <label className="lb-form">N&uacute;mero Externo<span className="red">*</span></label>
                        <div className="d-flex justify-content-end align-items-center">
                          <CustomTextField id="numExt" name="numExt" type="text" placeholder=""/>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4 px-xs-4x">
                        <label className="lb-form">N&uacute;mero Interno</label>
                        <div className="d-flex justify-content-end align-items-center">
                          <CustomTextField id="numInt" name="numInt" type="text" placeholder=""/>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4 px-xs-4x">
                        <label className="lb-form">C&oacute;digo Postal<span className="red">*</span></label>
                        <CustomTextField id="cp" name="cp" type="number" placeholder="" disabled
                          onChange={(event) => handleChangeValidateCP(event, setFieldValue, setFieldError)}/>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                        <label className="lb-form">Colonia<span className="red">*</span></label>
                        <CustomSelect id="colonia" name="colonia"
                          onChange={(event) => handleOnChangeColonia(event, setFieldValue)}>
                          {colonias.map((colonia) => (
                            <MenuItem key={colonia.c2} value={colonia.c2}>{colonia.c2}</MenuItem>
                          ))}
                        </CustomSelect>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                        <label className="lb-form">Ciudad<span className="red">*</span></label>
                        <CustomTextField id="ciudad" name="ciudad" type="text" placeholder="" disabled/>
                      </div>
                      {tipoPersona === 1 &&
                        <h3 className="lbl-titulo">Datos de contacto</h3>
                      }
                      {tipoPersona === 2 &&
                        <h3 className="lbl-titulo">Datos de contacto del contratante</h3>
                      }
                      <h6 className="lbl-t">Por favor valida tus datos de contacto para el env&iacute;o de tu p&oacute;liza y documentos</h6>
                      <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                        <label className="lb-form">Tel&eacute;fono<span className="red">*</span></label>
                        <CustomTextField id="telefono" name="telefono" type="text" placeholder=""/>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6 px-xs-6x">
                        <label className="lb-form">Correo electr&oacute;nico<span className="red">*</span></label>
                        <CustomTextField id="correo" name="correo" type="text" placeholder=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 offset-md-3">
              <Button variant="contained" name="continuar" id="continuar" className="w-100 form-button button-continuar"
                onClick={submitForm} disabled={isSubmitting}>
                CONTINUAR
              </Button >
            </div>
          </Form>
        )}
      </Formik>
    : <></>}

    </>
  );
}
