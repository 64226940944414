import React, { useEffect } from 'react';

import RegistroForm from './RegistroForm';
import './CompraExitosa.css';
import PhoneIcon from '@mui/icons-material/Phone';
import { useHistory } from 'react-router';

export default function CompraExitosa(props) {
  let history = useHistory();  
  const back = () => {
    history.push("/datos-vehicle");
  };

  
  return (<div className="body-estruct">
  <div className="container py-20x">
      <div className="row">
            <div className="col-12 align-self-center">
              <RegistroForm history={history}/>
            </div>  
          <button id="btn_dp_ayuda" className="btn-ayuda font-ayuda ocultar-btn" >
            <PhoneIcon/> Ayuda
          </button>
      </div>
  </div>
</div>)
}