import React, { useEffect, useState } from 'react';
import './DatosVehicle.css';
import { Form, Formik } from 'formik';
import { validationForm } from './ValidationFormYup';
import Button from '@mui/material/Button';
import CustomTextField from '../Forms/CustomTextField';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import CustomDatePicker from '../Forms/CustomDatePicker';
import CustomSelect from '../Forms/CustomSelect';
import { MenuItem } from '@material-ui/core';
import { CMB_MENOS_UNO, ERROR_MSG_1, ERROR_TITULO_MSG_1, MAX_LENGTH_NO_SERIE, MAX_LENGTH_PLACAS, MAYOR_EDAD } from '../Utils/Constantes';
import { calcularEdad, convertDDMMYYYYToDate, formatDDMMYYYY, maxLength, validaMayorEdad } from '../Utils/Utils';
import DatosVehicleApi from '../Api/DatosVehicle';
import Error from '../Estructura/Modal/Error';
import Loading from '../Estructura/Modal/Loading';
import CustomCheckbox from '../Forms/CustomCheckbox';
import { recotizar } from '../Utils/Recotizacion';


var errorTitulo = ERROR_TITULO_MSG_1;
var errorMsg = ERROR_MSG_1;
export default function RegistroForm(props) {
  const [uberSeguro, setUberSeguro] = useState(null);
  const [tipoPersona, setTipoPersona] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isDataReady, setDataReady] = useState(false);
  const [datosPersonales, setDatosPersonales] = useState({
    nombre: null,
    apPaterno: null,
    apMaterno: null,
    fechaNacimiento: null,
    genero: null,
    rfc: null,
    telefono: null,
    correo: null,
    contrasena: null,
    confirmar: null,
  });
  const [auto, setAuto] = useState({
    bran: null,
    class: null,
    description: null,
    model: null,
    type: null,
    noSerie: null,
    placas: null,
  });
  
  const [isOtroConductor, setOtroConductor] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  
  useEffect(() => {
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      setUberSeguro(uberSeguroSto);
      setTipoPersona(uberSeguroSto.tipoPersona);
      if (uberSeguroSto.tipoPersona === 2){
        setOtroConductor(true);
      }
      if(uberSeguroSto.datosPersonales){
        setDatosPersonales(uberSeguroSto.datosPersonales);
        setInitialValues({
          nombre: (uberSeguroSto.datosPersonales.nombre != null ? uberSeguroSto.datosPersonales.nombre: ''),
          apPaterno: (uberSeguroSto.datosPersonales.apPaterno != null ? uberSeguroSto.datosPersonales.apPaterno: ''),
          apMaterno: (uberSeguroSto.datosPersonales.apMaterno != null ? uberSeguroSto.datosPersonales.apMaterno: ''),
          fechaNacimiento: (uberSeguroSto.datosPersonales.fechaNacimiento != null ? convertDDMMYYYYToDate(uberSeguroSto.datosPersonales.fechaNacimiento) : new Date()),
          genero: (uberSeguroSto.datosPersonales.genero != null ? uberSeguroSto.datosPersonales.genero: ''),
          rfc: (uberSeguroSto.datosPersonales.rfc != null ? uberSeguroSto.datosPersonales.rfc: ''),
          descripcion: (uberSeguroSto.auto.description != null ? uberSeguroSto.auto.description: ''),
          modelo: (uberSeguroSto.auto.model != null ? uberSeguroSto.auto.model: ''),
          noSerie: (uberSeguroSto.auto.noSerie != null ? uberSeguroSto.auto.noSerie: ''),
          placas: (uberSeguroSto.auto.placas != null ? uberSeguroSto.auto.placas: ''),
          //codigoPromo: null,
          contrasena: '',
          confirmar: '',
          otroConductor: false,
        });
      }
      if(uberSeguroSto.auto){
        setAuto(uberSeguroSto.auto);
      }
      if(uberSeguroSto.datosPersonalesCurrent && uberSeguroSto.datosPersonalesCurrent.isOtroConductor){
        setOtroConductor(true);
        setInitialValues({
          nombre: (uberSeguroSto.datosPersonalesCurrent.nombre != null ? uberSeguroSto.datosPersonalesCurrent.nombre: ''),
          apPaterno: (uberSeguroSto.datosPersonalesCurrent.apPaterno != null ? uberSeguroSto.datosPersonalesCurrent.apPaterno: ''),
          apMaterno: (uberSeguroSto.datosPersonalesCurrent.apMaterno != null ? uberSeguroSto.datosPersonalesCurrent.apMaterno: ''),
          fechaNacimiento: (uberSeguroSto.datosPersonalesCurrent.fechaNacimiento != null ? convertDDMMYYYYToDate(uberSeguroSto.datosPersonalesCurrent.fechaNacimiento) : new Date()),
          genero: (uberSeguroSto.datosPersonalesCurrent.genero != null ? uberSeguroSto.datosPersonalesCurrent.genero: ''),
          rfc: (uberSeguroSto.datosPersonalesCurrent.rfc != null ? uberSeguroSto.datosPersonalesCurrent.rfc: ''),
          descripcion: (uberSeguroSto.auto.description != null ? uberSeguroSto.auto.description: ''),
          modelo: (uberSeguroSto.auto.model != null ? uberSeguroSto.auto.model: ''),
          noSerie: (uberSeguroSto.auto.noSerie != null ? uberSeguroSto.auto.noSerie: ''),
          placas: (uberSeguroSto.auto.placas != null ? uberSeguroSto.auto.placas: ''),
          //codigoPromo: null,
          contrasena: '',
          confirmar: '',
          otroConductor: true,
        });
      }
      setLoading(false);
      setDataReady(true);
    }
  }, []);

  const handleChangeOtroConductor = (event, setFieldValue) => {
    setOtroConductor(event.target.checked);
    setFieldValue("otroConductor", event.target.checked);
  };

  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError, values) => {
    if(validaMayorEdad(fecha)){
      if(tipoPersona === 1){
        /** prende modal de recotiazcion*/
        if(calcularEdad(fecha) !== Number(uberSeguro.datosPersonales.edad)){
          handleMensajesError(1);
          /** prende bandera recotizo*/
          window.sessionStorage.setItem("recotizo",true);
          setModalShow(true);
        }
        setLoading(true);
        /** hace la recotiazcion*/
        handleRecotizar(setFieldValue,"fechaNacimiento",fecha, values);
      } else {
        setFieldValue("fechaNacimiento",fecha);
      }
    } else {
      setFieldError("fechaNacimiento",MAYOR_EDAD);
    }
  };
  
  /* funciones para combo Genero */
  const handleOnChangeGenero = (e, setFieldValue, values) => {
    let val = e.target.value;
    if(tipoPersona === 1){
      /** prende modal de recotiazcion*/
      handleMensajesError(2);
      setModalShow(true);
      setLoading(true);
      /** hace la recotiazcion*/
      handleRecotizar(setFieldValue, "genero", val, values);
    } else {
      setFieldValue("genero", val);
    }
  };
  /*terminan funciones para combo Genero */

  const handleMensajesError = (origen) => {
    switch(origen) {
      case 1: //edad
        errorTitulo = "Cambio de fecha";
        if(isOtroConductor){
          errorMsg = "La edad del conductor habitual es distinta a la del contratante por lo que la prima puede cambiar.";
        } else {    
          errorMsg = "La edad del contratante es distinta a la de la cotización por lo que la prima puede cambiar.";
        }
        break;
      case 2: //genero
        errorTitulo = "Cambio de sexo";
        errorMsg = "El sexo del conductor habitual es distinta a la del contratante por lo que la prima puede cambiar.";
        break;
      default:
        break;
    }
  };

  const handleChangePassword = (e, setFieldValue) => {
    const { target: { value, name }, } = e;
    const valorFinal = value.replace(/\s/g, "");
    setFieldValue(name, valorFinal);
  };

  const handleChangeNoSerie = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    const valorFinal = maxLength(value, MAX_LENGTH_NO_SERIE).replace(/[^a-zA-Z0-9]/g, "");
    setFieldValue(name, valorFinal);
  };

  const handleChangePlacas = (event, setFieldValue) => {
    const { target: { value, name }, } = event;
    const valorFinal = maxLength(value, MAX_LENGTH_PLACAS).replace(/[^a-zA-Z0-9]/g, "");
    setFieldValue(name, valorFinal);
  };

  const handleRecotizar = (setFieldValue, fieldName, fieldValue, values) => {    
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      if(window.sessionStorage.getItem("type")){
        const typePlan = JSON.parse(window.sessionStorage.getItem(window.sessionStorage.getItem("type")));
        recotizar(uberSeguroSto, typePlan, setLoading, setFieldValue, fieldName, fieldValue, values);
      }
    }
  };

  const continuar = (values, setSubmitting) => {
    setLoading(true);
    let datosPersonalesCurrent = null;
    if(isOtroConductor){
      datosPersonalesCurrent = {
        nombre: values.nombre,
        apPaterno: values.apPaterno,
        apMaterno: values.apMaterno,
        fechaNacimiento: formatDDMMYYYY(values.fechaNacimiento),
        genero: values.genero,
        rfc: values.rfc,
        contrasena: values.contrasena,
        confirmar: values.confirmar,
        telefono: datosPersonales.telefono,
        correo: datosPersonales.correo,
        isOtroConductor: isOtroConductor,
      }
    } else {
      datosPersonalesCurrent = {
        nombre: datosPersonales.nombre,
        apPaterno: datosPersonales.apPaterno,
        apMaterno: datosPersonales.apMaterno,
        fechaNacimiento: datosPersonales.fechaNacimiento,
        genero: datosPersonales.genero,
        rfc: datosPersonales.rfc,
        telefono: datosPersonales.telefono,
        correo: datosPersonales.correo,
        contrasena: values.contrasena,
        confirmar: values.confirmar,
        isOtroConductor: isOtroConductor,
      }
    }
    const autoCurrent = {
      bran: auto.bran,
      class: auto.class,
      description: values.descripcion,
      model: values.modelo,
      type: auto.type,
      noSerie: values.noSerie,
      placas: values.placas,
    };
    const uberSeguroSto = {
      tipoPersona: uberSeguro.tipoPersona,
      modelo: uberSeguro.modelo,
      plataformas: uberSeguro.plataformas,
      auto: autoCurrent,
      datosPersonales: uberSeguro.datosPersonales,
      datosPersonalesCurrent: datosPersonalesCurrent,
      domicilio: uberSeguro.domicilio,
    };
    window.sessionStorage.setItem("uberSeguro",JSON.stringify(uberSeguroSto));
    setSubmitting(false);
    var params = {
        "numeroDeSerie": values.noSerie,
        "negocio_id": 934,
        "jsonEntrada": {
            "cia": 20
        }
    }
    errorTitulo = ERROR_TITULO_MSG_1;
    errorMsg = ERROR_MSG_1;
    DatosVehicleApi.serie(params)
        .then(res => {
          if(res.data.valid){
            const typePlan = window.sessionStorage.getItem("type");
            const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
            if(window.sessionStorage.getItem("type")){
              const typePlan = window.sessionStorage.getItem("type");
              window.dataLayer.push({
                'event': 'formStep3Submit',
                 'eventCategory': 'Formulario.Seguro.AutoApp',
                 'eventAction': 'Step3',
                 'eventLabel': 'DatosVehiculo',
                 'çobertura': typePlan,
                 'SeguroSeleccionado': 'AXA'
              });
              window.dataLayer.push({
                "event" : "cocheapp_datos_vehiculo",      //Static data,
                "boton_datos_vehiculo":"Continuar",
                "Cotización": cotizacionJson.SolicitudID

              });
            }
            setLoading(false);
            props.history.push("/resumen-compra");
          } else {
            setLoading(false);
            setModalShow(true);
          }
        })
        .catch(error => {
          setLoading(false);
          setModalShow(true);
        });
  };

  return (
    <>
      <Error
        open={modalShow}
        handleClose={() => setModalShow(false)}
        titulo={errorTitulo}
        mensaje={errorMsg}
      />
      <Loading
        open={isLoading}
        handleClose={() => setLoading(false)}
      />
      {isDataReady ?         
      <Formik validateOnChange={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            continuar(values, setSubmitting);
          }}
          validationSchema={validationForm}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError}) => (
        <Form>
          <div className="col-12 align-self-center">
            <div className="col-12">
              <div className="row">
                <div className="col align-self-center">
                  <div className="tarjeta-form">
                    <div className="row">
                      <div className="row-sio w-100 text-right">
                        <CustomCheckbox name="otroConductor" onChange={(event) => handleChangeOtroConductor(event, setFieldValue)}/>Otro conductor
                          
                      </div>
                      <div className="row-sio w-100">
                          <label className="conductor-titulo">Contratante</label>
                      </div>
                      <div className="w-100 py-10x px-10x">
                          <label className="lb-correo">{datosPersonales.nombre} {datosPersonales.apPaterno} {datosPersonales.apMaterno}</label>
                      </div>
                    </div>
                    <div className="row">
                      {isOtroConductor &&
                            <>
                              <div className="row-sio w-100">
                                <label className="conductor-titulo">Conductor Habitual</label>
                            </div>
                              <div className="row-sio w-100">
                                <label className="lb-form">Nombre Conductor<span className="red">*</span></label>
                                <CustomTextField id="nombre" name="nombre" type="text" placeholder=""/>
                              </div>
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                                  <label className="lb-form">Apellido Paterno<span className="red">*</span></label>
                                  <CustomTextField id="apPaterno" name="apPaterno" type="text" placeholder=""/>
                                </div>
                                <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                                  <label className="lb-form">Apellido Materno</label>
                                  <CustomTextField id="apMaterno" name="apMaterno" type="text" placeholder=""/>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                                  <label className="lb-form">Fecha de Nacimiento<span className="red">*</span></label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <CustomDatePicker
                                        name="fechaNacimiento"
                                        variant="inline"
                                        autoOk={true}
                                        format="dd/MM/yyyy"
                                        onChange={(newValue) => handleChangeFechaNacimiento(newValue, setFieldValue, setFieldError, values)}
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                                  <label className="lb-form">Sexo</label>
                                  <CustomSelect id="genero" name="genero"
                                    onChange={(event) => handleOnChangeGenero(event, setFieldValue, values)}>
                                    <MenuItem value={CMB_MENOS_UNO}>Sexo</MenuItem>
                                    <MenuItem value={0}>Hombre</MenuItem>
                                    <MenuItem value={1}>Mujer</MenuItem>
                                  </CustomSelect>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12">
                                  <label className="lb-form">RFC<span className="red">*</span></label>
                                  <CustomTextField id="rfc" name="rfc" type="text" placeholder=""/>
                                </div>
                              </div>
                            </>
                      }
                      <h3 className="lbl-titulo my-10x">Datos del Veh&iacute;culo</h3>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                          <label className="lb-form">Descripción del vehículo<span className="red">*</span></label>
                          <input name="Descripcion" id="txDescripcion" type="text" placeholder="" maxLength="70" value ={auto.description} disabled/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4">
                          <label className="lb-form">Modelo<span className="red">*</span></label>
                          <input name="Modelo" id="txModelo" value ={auto.model} type="text" placeholder="" maxLength="13" disabled/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                          <label className="lb-form">N&uacute;mero de Serie<span className="red">*</span></label>
                            <CustomTextField id="noSerie" name="noSerie" type="text" placeholder=""
                              onChange={ (event) => handleChangeNoSerie(event, setFieldValue)}/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                          <label className="lb-form">Placas<span className="red">*</span></label>
                            <CustomTextField id="placas" name="placas" type="text" placeholder=""
                              onChange={ (event) => handleChangePlacas(event, setFieldValue)}/>
                        </div>
                      </div>
                    </div>
                    <h3 className="lbl-titulo my-10x">Tu Cuenta</h3>
                    <div className="row" >
                      <div className="w-100 py-15x px-10x">
                        <label className="lb-correo">{datosPersonales.correo}</label>
                      </div>
                      <div className="w-100">
                        <label className="lb-form">Captura una contrase&ntilde;a<span className="red">*</span></label>
                          <CustomTextField id="contrasena" name="contrasena" type="password" placeholder=""
                            onChange={ (event) => handleChangePassword(event, setFieldValue)}/>
                      </div>
                      <div className="w-100">
                        <label className="lb-form">Repite la contrase&ntilde;a<span className="red">*</span></label>
                          <CustomTextField id="confirmar" name="confirmar" type="password" placeholder="" 
                            onChange={ (event) => handleChangePassword(event, setFieldValue)}/>
                        <span className="lb-form bold">Esta contrase&ntilde;a te servir&aacute; para acceder a tu cuenta en el futuro</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div> 
          <div className="col-md-6 offset-md-3">
            <Button variant="contained" name="continuar" id="continuar" className="w-100 form-button button-continuar"
              onClick={submitForm} disabled={isSubmitting}>
              CONTINUAR
            </Button >
          </div>
        </Form>
        )}
      </Formik>
      : <></>}
                
    </>
  );
}
