import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import './Inicio.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogAyuda from '../Inicio/DialogAyuda';
import PhoneIcon from '@mui/icons-material/Phone';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { purple, lightBlue } from '@mui/material/colors';


{/*const style = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
  background: 'rgba(37, 211, 102, 1)',
};*/}
const styles = {
  margin: 0,
  top: '100px;',
  right: 20,
  bottom: 100,
  left: 'auto',
  position: 'fixed',
  background: 'rgba(255,255,255)',
};

export default function FloatingButtons() {

  const [openFormModal, setOpenFormModal] = React.useState(false);

  const handleClickClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenFormModal(false);
    }
  };

  const handleClickModal = () => {
    handleClickOpen();
  };
  const handleClickOpen = () => {
    setOpenFormModal(true);
  };


  {/*const handleClickWhat = () => {
    const url = 'https://api.whatsapp.com/send/?phone=5215543269037';
    window.open(url, '_blank');
  };*/}


  return (
    <>
      <Dialog open={openFormModal} onClose={(event, reason) => handleClickClose(event, reason)} disableEscapeKeyDown={true}
        maxWidth="sm" fullWidth={false}>
        <>
          <DialogAyuda handleClose={handleClickClose} />
        </>
      </Dialog>

      <Box id="contac">
        {/*<Fab variant="extended" style={style} onClick={handleClickWhat}>
      <Button startIcon={<WhatsAppIcon sx={{ color: purple[50]}}/>}><div className='textfab'>
        &iexcl;Cont&aacute;ctanos!
        </div></Button>
      </Fab>*/}
        <Fab variant="extended" className='btn-flotante' style={styles} onClick={handleClickModal}>
          <Button startIcon={<PhoneIcon sx={{ color: lightBlue[500] }} />}>
            <div className='textfab2'  style={{ textTransform: 'capitalize' }}>
              ¿Necesitas Ayuda?
            </div>
          </Button>
        </Fab>


      </Box>

    </>
  );
}