import axios from "axios";
import { PATH } from "../Utils/Constantes";
import { singleParamToDefaultParams } from "../Utils/Utils";

// Create instance called instance
const instance = axios.create({
  baseURL: PATH,
});


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  Sio4CodigoPostalAPI: (cp) =>
    instance({
      method: "GET",
      url: "sio4apolizas-new/Sio4CodigoPostalAPI",
      params: singleParamToDefaultParams(cp),
    }),
  }
