import React, { useEffect } from 'react';
import './Toolbar.css';
import interLogo from '../../../assets/images/logo_Inter.png';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PageviewIcon from '@mui/icons-material/Pageview';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import PhoneIcon from '@mui/icons-material/Phone';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import ApiInicio from "../../Api/Inicio";


// export className Profile {
//   nombre= '';
//   correo= '';
//   photo= '';

// }

export default function Toolbar(props) {
  
  // const isPremier = (window.location.hostname === 'cocheseguroclubpremier.inter.mx') ? true : false;        
  // const isUpSivale = (window.location.hostname === 'cochesegurosivale.inter.mx') ? true : false;
  // const isManpower = (window.location.hostname === 'cocheseguromanpower.inter.mx') ? true : false;
  // const isCenegas = (window.location.hostname === 'cochesegurocenagas.inter.mx') ? true : false;
  // @ViewChild('servicios', {static: false}) productPanel: ElementRef;
  // @ViewChild('cotizador', {static: false}) quotePanel: ElementRef;
  // @ViewChild('beneficios', {static: false}) benefitsPanel: ElementRef;
  const isPayWithCard = true;
  const isEmployee = false;
  const isEmployeeSivale = false; 
  const [isMenuActive, setIsMenuActive] = React.useState(false);
  const [isProfile, setIsProfile] = React.useState(false);//TODO: este se modifica en onValidaOpciones y onMonitoreo
  const [profile] = React.useState();
  
  //(panel: ElementRef, subtract: number)
  /*const localizaPanel = (panel, subtract) => {
    window.scrollBy({
      top: panel?.nativeElement.getBoundingClientRect().top - subtract,
      left: 0,
      behavior: 'smooth'
    });
  };*/

  const handleToInicio = () => {
    localStorage.removeItem('medioPago');
    window.location.href = "https://inter.mx";
  };
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleActiveMenu = () => {
    setIsMenuActive(!isMenuActive);
  }

  const handleToMisSeguros = () => {
    this.router.navigate(['/mi-cuenta']);//router aun no se define
  };
    
  const handleToCerrarSession = () => {
    localStorage.clear();
    //redirecciona
  };

  const handleOpenModalMarcame = () => {
      // try {
      //     this.dialog.open(CotizacionAyudaComponent, { disableClose: false });
      // } catch (error) {
      //     this.snackBar.open('InicioComponent.openModalMarcame().error', error, { duration: this.const.SNACK_BARTIME });
      // }
  };

  const handleCopyToClipboard = () => {
    let linkToBeCopied = '';
    if (localStorage.ifExist("logConsumoId")) {
      let logConsumoId = localStorage.getItem('logConsumoId');
      if (logConsumoId.includes(' ')) {
        logConsumoId = logConsumoId.replace(/ /g, '+')          
      }
      console.log("CAMBIAR URLS?");
      switch(window.location.hostname) {
        case 'inter-autos-qa.web.app':
        case 'localhost':
          linkToBeCopied = `https://inter-autos-qa.web.app/auto-login-ayuda?logConsumoId=${logConsumoId}`;
          break;
        case 'empleadoscocheseguro.inter.mx':
          linkToBeCopied = `https://cocheseguro.inter.mx/auto-login-ayuda?logConsumoId=${logConsumoId}`;
          break;
        case 'cochesegurosivale.inter.mx':                    
          linkToBeCopied = `https://cochesegurosivale.inter.mx/auto-login-ayuda?logConsumoId=${logConsumoId}`;
          break;
        default:
          break;
      }
      const result = this.clipboard.copy(linkToBeCopied);
      const message = { title: "", body: "" };
      if (result) {
        message.title = "Link copiado exitosamente al portapapeles.";
        message.body = null;
      } else {
        message.title = "Error al copiar link.";
        message.body = null;
      }
      alert("snackBar");
      // this.snackBar.open(message.title, message.body, { duration: new Configuration().SNACK_BARTIME });
    }
  }

  const handleToQuienesSomos = () => {
    window.open("https://seguros.inter.mx/quienes-somos-2/");
  };

  // const openSectionProduct = () => {
  //   // this.productItemEvent.emit('servicios')
  //       console.log("openSectionProduct");
  // };

  // const openSectionQuote = () => {
  //   // this.quoteItemEvent.emit('cotizador')
  //       console.log("openSectionQuote");
  // };

  // const openSectionBenefits = () => {
  //   // this.benefitsItemEvent.emit('beneficios')
  //       console.log("openSectionBenefits");
  // };

  const onLogin = () => {
    console.log("Abre modal de login");
  }

  const validaDominio = () => {
      return (isPayWithCard && isEmployee) || (isEmployeeSivale && isPayWithCard);
  }

  useEffect(() => {
    let cocheSeguro;
    if(localStorage.getItem("cocheSeguro")){
      cocheSeguro = JSON.parse(localStorage.getItem("cocheSeguro"));
    }
    if (cocheSeguro?.m && cocheSeguro.m !== "zl8mIfg3UrGeD1CvQu7UddMRtZ9eljnfGDTy+oOne/8=") {
      setIsProfile(true);
      let params = { "a": cocheSeguro.a };
      ApiInicio.contratante(params)
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        console.log(error);
      })
    }
  }, []);


  return (
    <>
      <div className="toolbar">
        <div className="table-sio w-100">
          <div className="cell-sio text-middle text-md-left w-lg-235x">
            <img src={interLogo} className="logo" onClick={handleToInicio} alt=""/>
          </div>
          <div className="cell-sio text-middle display-mobil-tablet-none text-center">
            <div className="table-sio w-100">
              <div className="cell-sio text-middle text-center">
                <a href="" className="link"  rel="noreferrer">
                  Seguros
                </a>
              </div>
              <div className="cell-sio text-middle text-left">
                <a href="https://seguros.inter.mx/quienes-somos-2/" className="link" id="btn_quienes_somos" target="_blank" rel="noreferrer">
                  Quiénes Somos
                </a>
              </div>
              <div className="cell-sio text-middle text-center">
                <a href="https://inter.mx/blog/" className="link" target="_blank" rel="noreferrer">
                  Blog
                </a>
              </div>
              <div className="cell-sio text-middle text-right">
                {isProfile ? (
                  <span>
                    <div className="table-sio underline float-right">
                      <div className="cell-sio text-middle text-right">
                        <span onClick={handleOpenMenu}>
                          <h4 className="profile-t text-right">
                            {profile.nombre}
                          </h4>
                          <p className="profile-s text-right">{profile.correo}</p>
                        </span>
                      </div>
                      <div className="cell-sio text-middle px-10x">
                        <Button
                          id="btn-menu2"
                          className="btn-icon foto text-middle"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleOpenMenu}
                        >
                          <img className="profile-img" src={profile.photo} alt=""/>
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleCloseMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            id="btn_mis_seguros"
                            onClick={handleToMisSeguros}
                          >
                            Mis Seguros
                          </MenuItem>
                          <MenuItem
                            id="btn_cierra_session"
                            onClick={handleToCerrarSession}
                          >
                            <span>Cerrar Sessi&oacute;n</span>
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </span>
                  ) : (
                  <>
                    <div className="column ocultar-btn mt-15">
                      <img className="logo" src={interLogo} onClick={handleToInicio} alt=""/>
                      <CancelIcon className="btn-close float-right" />
                    </div>
                    <div className="table-sio w-100 text-center ocultar-btn">
                      <Divider />
                      <div className="row-sio">
                        {/* <a className="link line-h" id="btn_menu_cotizador" onClick={openSectionQuote} > */}
                          Cotizador
                        {/* </a> */}
                      </div>
                      <div className="row-sio">
                        {/* <a className="link line-h" id="btn_menu_beneficiarios" onClick={openSectionBenefits} > */}
                          Beneficios
                        {/* </a> */}
                      </div>
                      <div className="row-sio">
                        <a href="https://storage.googleapis.com/coche-seguro-inter/Preguntasfrecuentes.pdf" className="link line-h" id="btn_menu_preg_frec" 
                        target="_blank" rel="noreferrer">
                          Preguntas frecuentes
                        </a>
                      </div>
                      <div className="row-sio">
                        {/* <a id="btn_menu_productos" className="line-h link" onClick={openSectionProduct} > */}
                          Nuestros productos
                        {/* </a> */}
                      </div>
                      <div className="row-sio">
                        <a href="https://seguros.inter.mx/quienes-somos-2/" id="btn_menu_nosotros" className="link line-h" target="_blank" rel="noreferrer">
                          Sobre nosotros
                        </a>
                      </div>
                      <div className="row-sio">
                        {/* <a id="btn_menu_contacto" className="link line-h" onClick={handleOpenModalMarcame} > */}
                          Contacto
                        {/* </a> */}
                      </div>
                      <div className="row-sio">
                        {/* <a id="btn_menu_login" className="link line-h" onClick={onLogin} > */}
                          Login
                        {/* </a> */}
                      </div>
                    </div>

                    <div>
                      <div id="btn_login" className="link" onClick={onLogin}>
                        <div className="table-sio float-right">
                          <div className="cell-sio text-middle text-center">
                            <AccountCircleIcon className="text-middle" />
                          </div>
                          <div className="cell-sio text-middle text-center pl-10x">
                            Login de clientes
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="cell-sio text-middle w-ssm-50x text-center w-lg-235x">
            <div className="cell-sio ocultar-btn">
            </div>
            <div className="cell-sio">
            </div>
          </div>   
          {
            validaDominio() ? 
              <div className="cell-sio text-middle">
                <button className="button-sharet-link"  onClick={handleCopyToClipboard}>Compartir link</button> 
              </div>
            : <></>
          }
        </div>
      </div>
    </>
  );
}