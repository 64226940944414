import axios from "axios";
import { APPSPOT_PATH } from "../Utils/Constantes";
import { singleParamToDefaultParams } from "../Utils/Utils";

// Create instance called instance
const instance = axios.create({
  baseURL: APPSPOT_PATH,
});


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  emitir: (params) =>
    instance({
      method: "POST",
      url: "/emision",
      data: params,
    })
  }
