import React, { useEffect, useState } from 'react';

import RegistroForm from './RegistroForm';
import './ResumenCompra.css';
import PhoneIcon from '@mui/icons-material/Phone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useHistory } from "react-router-dom";

export default function ResumenCompra(props) {
    let history = useHistory();  
    const [tipoPersona, setTipoPersona] = useState(null);
    const back = () => {
      history.push("/datos-vehicle");
    };
    useEffect(() => { 
        window.dataLayer.push({
          'event': 'formStep4View'
        });   
      if(window.sessionStorage.getItem("uberSeguro")){
        const uberSeguroSto = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
        setTipoPersona(uberSeguroSto.tipoPersona);
      }
    }, []);

    return (<div className="body-estruct">
    <div className="container py-20x">
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <div id="btn_regresar_cotizacion" className="btn-back" onClick={back}> Regresar</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="table-sio m-auto">
                            <div className="cell-sio">
                                <div className="icon-paso-num">1</div>
                                {tipoPersona === 1 &&
                                <div className="lbl-paso">Datos Personales</div>
                                }
                                {tipoPersona === 2 &&
                                <div className="lbl-paso">Datos de la Empresa</div>
                                }
                                <div className="lbl-separate-disabled">< ArrowForwardIosIcon/></div>
                                <div className="icon-paso-num">2</div>
                                {tipoPersona === 1 &&
                                <div className="lbl-paso">Datos del Vehículo</div>
                                }
                                {tipoPersona === 2 &&
                                <div className="lbl-paso">Datos del Vehículo y Conductor</div>
                                }
                                <div className="lbl-separate-disabled">< ArrowForwardIosIcon/></div>
                                <div className="icon-paso-num">3</div>
                                <div className="lbl-paso">Resumen de tu compra</div>
                                <div className="lbl-separate-disabled">< ArrowForwardIosIcon/></div>
                                <div className="icon-paso-num-disabled">4</div>
                                <div className="lbl-paso-disabled d-none d-lg-block">Datos de Pago</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    </div>
                </div>
            </div>
              <div className="col-12 align-self-center">
                <RegistroForm history={history}/>
              </div>  
            <button id="btn_dp_ayuda" className="btn-ayuda font-ayuda ocultar-btn" >
                <PhoneIcon/> Ayuda
            </button>
        </div>
    </div>
</div>)
}