import * as yup from "yup";
import { TEXT_MAX, TEXT_MIN, TEXT_MIN_MAX, VALOR_NECESARIO } from "../Utils/Constantes";

export  const validationForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20,TEXT_MAX),
  apPaterno: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(25,TEXT_MAX),
  apMaterno: yup
    .string()
    .min(1, VALOR_NECESARIO)
    .max(25,TEXT_MAX),
  fechaNacimiento: yup
    .date()
    .required(VALOR_NECESARIO),
  genero: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20,TEXT_MAX),
  rfc: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(13,TEXT_MAX),
  descripcion: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(100,TEXT_MAX),
  modelo: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(13,TEXT_MAX),
  noSerie: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(17, TEXT_MIN)
    .max(17,TEXT_MIN_MAX),
  placas: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(10,TEXT_MAX),
  contrasena: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(150,TEXT_MAX),
  confirmar: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(150,TEXT_MAX)
    .test(
      'valida-confirmacion',
      '¡La contraseña no coincide! Favor de capturar la misma contraseña.',
      (val, context) => {
        const { contrasena } = context.parent;
        return contrasena === val;
      }
    ),
 });
