import axios from "axios";
import { PATH } from "../Utils/Constantes";
import { singleParamToDefaultParams } from "../Utils/Utils";

// Create instance called instance
const instance = axios.create({
  baseURL: PATH,
});

const paramsToAutosBuscador = (descripcion, modelo) => {
  return {
    s4_key:"uLmdvb2dsZS5jb20vY2hhdC1kYXRhYmFzZS05NTdjMSIsIm5hbWUiOiJIZWJlc",
    d: descripcion,
    m: modelo,
    c: 20,
    p: 0,
    negocio_id: 61,
    tipoVehiculoBuscador: 10,
  }
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  contratante: (params) =>
    instance({
      method: "GET",
      url: "InterAutosGetContratanteApi",
      params: params,
    }),
  CodigoPostalvalidacion: (f1) =>
    instance({
      method: "GET",
      url: "data-transfer-api/CodigoPostalvalidacionAPI",
      params: singleParamToDefaultParams(f1),
    }),
  AutosBuscador: (descripcion, modelo) =>
    instance({
      method: "GET",
      url: "quattro-autos-ws/QuattroAutosBuscador",
      params: paramsToAutosBuscador(descripcion, modelo),
    }),
  }
