import * as yup from "yup";
import { AUTOCOMPLETE, CMB_NECESARIO, CORREO_INVALIDO, CP_INVALIDO, EDAD_INVALIDA, TELEFONO_INVALIDO, TEXT_MAX, VALOR_NECESARIO, VALOR_NUMERO } from "../Utils/Constantes";


export const validationMoralForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20, TEXT_MAX),
  correo: yup//validarCorreo() ES SERVICIO
    .string()
    .required(VALOR_NECESARIO)
    .email(CORREO_INVALIDO)
    .min(1, VALOR_NECESARIO)
    .max(80, TEXT_MAX),
  telefono: yup//validPhoneNumber
    .string()
    .required(VALOR_NECESARIO)
    .typeError(VALOR_NUMERO)
    .length(10, TELEFONO_INVALIDO)
    .test(
      "formato",
      TELEFONO_INVALIDO,
      (val) => {
        var regex = /^\D*\d{10}$/;
        return regex.test(val + "");
      }
    ),
  cp: yup
    .string()
    .required(CP_INVALIDO)
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test(
      "formato",
      CP_INVALIDO,
      (val) => {
        var regex = /^\D*\d{5}$/;
        return regex.test(val);
      }
    ),
  modelo: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  descripcionDes: yup
    .string()
    .required(AUTOCOMPLETE),
});

export const validationFisicaInicioForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20, TEXT_MAX),
  correo: yup//validarCorreo() ES SERVICIO
    .string()
    .required(VALOR_NECESARIO)
    .email(CORREO_INVALIDO)
    .min(1, VALOR_NECESARIO)
    .max(80, TEXT_MAX),
  telefono: yup//validPhoneNumber
    .string()
    .required(VALOR_NECESARIO)
    .typeError(VALOR_NUMERO)
    .length(10, TELEFONO_INVALIDO)
    .test(
      "formato",
      TELEFONO_INVALIDO,
      (val) => {
        var regex = /^\D*\d{10}$/;
        return regex.test(val + "");
      }
    ),
  genero: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO)
    .max(1, CMB_NECESARIO),
  edad: yup
    .number()
    .required(VALOR_NECESARIO)
    .typeError(VALOR_NUMERO)
    .min(18, EDAD_INVALIDA)
    .max(99, EDAD_INVALIDA),
  cp: yup
    .string()
    .required(CP_INVALIDO)
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test(
      "formato",
      CP_INVALIDO,
      (val) => {
        var regex = /^\D*\d{5}$/;
        return regex.test(val);
      }
    ),
  modelo: yup
    .number()
    .required(VALOR_NECESARIO)
    .min(0, VALOR_NECESARIO),
  descripcionDes: yup
    .string()
    .required(AUTOCOMPLETE),
});

export const validationDialogTelefonoAyuda = yup.object().shape({
  telefonoAyuda: yup
  .string()
  .required(VALOR_NECESARIO)
  .typeError(VALOR_NUMERO)
  .length(10, TELEFONO_INVALIDO)
  .test(
    "formato",
    TELEFONO_INVALIDO,
    (val) => {
      var regex = /^\D*\d{10}$/;
      return regex.test(val + "");
    }
    ),
});

export const validationDialogTelefonoNo = yup.object().shape({
  telefonoNo: yup
  .string()
  .required(VALOR_NECESARIO)
  .typeError(VALOR_NUMERO)
  .length(10, TELEFONO_INVALIDO)
  .test(
    "formato",
    TELEFONO_INVALIDO,
    (val) => {
      var regex = /^\D*\d{10}$/;
      return regex.test(val + "");
    }
    ),
});