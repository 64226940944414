import React from 'react';
import './Footer.css';
import interMXV from '../../../assets/images/footer/INTERMX V-Wh.svg';
import googleGris from '../../../assets/images/footer/GoogleGris.png';
import appleGris from '../../../assets/images/footer/AppleGris.png';
import logoInstagram from '../../.../../../assets/images/redes/Logo_instagram.svg';
import logoFacebook from '../../.../../../assets/images/redes/Logo_facebook.svg';
import logoYouTube from '../../.../../../assets/images/redes/Logo_youtube.svg';
import logoLinkedin from '../../.../../../assets/images/redes/Logo_linkedin.svg';
import logoTiktok from '../../.../../../assets/images/redes/Logo_TIKTOK.svg';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export default function Footer(props) {

  const openWindow = (valor) => {
    switch (valor) {
      case 1:
        window.open("https://seguros.inter.mx/servicios/#seguros"); // Seguros y Fianzas
        break;
      case 3:
        window.open("https://seguros.inter.mx/servicios/#reaseguro"); // Reaseguro
        break;
      case 4:
        window.open("https://seguros.inter.mx/servicios/#microseguros"); // Microseguros
        break;
      case 5:
        window.open("https://inter.mx/servicios/"); // Líneas financieras
        break;
      case 6:
        window.open("https://seguros.inter.mx/servicios/#solucionestpa"); // Soluciones TPA
        break;
      case 7:
        // Seguro de coche
        if (this.const.PLATFORM) {
            window.location.href = this.const.URLPLATFORM + 'inicio';
        } else {
            this.router.navigate(['/inicio']);
        }
        break;
      case 8:
        window.open("https://productos.inter.mx/gastos-medicos-mayores/"); // Gastos Médicos Mayores
        break;
      case 9:
        window.open("https://productos.inter.mx/bien-seguro/"); // Tu casa y sus contenidos
        break;
      case 10:
        window.open("https://seguros.inter.mx/seguro-covid-protect-inter/"); // Seguro Covid
        break;
      case 11:
        window.open("tel:5544246837"); // telefono
        break;
      case 12:
        window.open("mailto:contacto@inter.mx"); // email
        break;
      case 13:
        window.open("https://www.interbi.mx/qlikview/FormLogin.htm"); // login
        break;
      case 14:
        window.open("https://inter.mx/aviso-de-privacidad"); // aviso de privacidad
        break;
      case 15:
        window.open("https://facebook.com/intermx/"); // facebook
        break;
      case 16:
        window.open("https://www.instagram.com/interproteccion/"); // instagram
        break;
      case 17:
        window.open("https://www.linkedin.com/company/interproteccion/"); // linkedin
        break;
      case 18:
        window.open("https://cocheseguro.inter.mx/inicio");
        break;
      case 19:
        window.open("https://mascotasegura.inter.mx/seguro-mascota/landing");
        break;
      case 20:
        window.open("https://productos.inter.mx/vida-segura/");
        break;
      case 21:
        window.open("https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA");
        break;
      case 22:
        window.open("https://vm.tiktok.com/ZMeSRnwDw/");
        break;
      default:
        break;
    }
  }
  return (
    <>
      <div className="footer" >
    <div className="table-sio w-100 h-100">
        <div className="row-sio">
            <div className="cell-sio text-middle">
                <div className="container m-auto mt-40x">
                    <div className="row">
                        <div className="mt-4 col-lg-2 col-md-2 col-12">
                          <img className="logoInter" src={interMXV} alt=""/>
                        </div>
                        <div className="mt-4 col-lg-2 col-md-2 col-12">
                            <p >Servicios</p>
                            <p className="white link mb-1rem"  onClick={() => openWindow(1)}>Seguros y Fianzas</p>
                            <p className="white link mb-1rem"  onClick={() => openWindow(3)}>Reaseguro</p>
                            <p className="white link mb-1rem"  onClick={() => openWindow(4)}>Microseguros</p>
                            <p className="white link mb-1rem"  onClick={() => openWindow(5)}>Líneas financieras</p>
                        </div>
                        
                        <div className="mt-4 col-lg-2 col-md-2 col-12">
                            <p >Nuestros productos</p>
                            <p className="white link mb-1rem"   onClick={() => openWindow(18)}>Seguro de coche</p>
                            <p className="white link mb-1rem"   onClick={() => openWindow(8)}>Médico Seguro</p>
                            <p className="white link mb-1rem"   onClick={() => openWindow(9)}>Bien Seguro</p>
                            <p className="white link mb-1rem"   onClick={() => openWindow(19)}>Mascota Segura</p>
                            <p className="white link mb-1rem"   onClick={() => openWindow(20)}>Vida Segura</p>
                        </div>

                        <div className="mt-4 col-lg-2 col-md-2 col-12">
                            <p >Contáctanos</p>
                            <p className="white link mb-2rem"  onClick={() => openWindow(11)}>
                              <PhoneIcon/>&nbsp;&nbsp;55442 46837
                            </p>
                            <p className="white link mb-2rem"  onClick={() => openWindow(12)}>
                              <EmailIcon/>&nbsp;&nbsp;hola@inter.mx
                            </p>
                        </div>

                        <div className="mt-4 col-lg-2 col-md-2 col-12">
                            <p >Clientes</p>
                            <p className="white link mb-1rem"  onClick={() => openWindow(13)}>Login</p>
                            <br />
                        </div>

                        <div className="mt-4 col-lg-2 col-md-2 col-12">
                          <p className="footer-secciones">Obten nuestra app</p>

                          <a target="blank" href="https://play.google.com/store/apps/details?id=grut.inter.mx">
                            <img className="footer-logo-tienda" src={googleGris} alt=""/>
                          </a>
                          <a target="blank" href="https://apps.apple.com/mx/app/intermx/id1548367767?l=en">
                             <img className="footer-logo-tienda" src={appleGris} alt=""/>
                          </a>
                          <p className="footer-siguenos">Siguenos</p>
                            <span className="white link" onClick={() => openWindow(16)}>
                              <img className="redes" src={logoInstagram} alt=""/>
                            </span>
                            <span className="white link" onClick={() => openWindow(15)}>
                              <img className="redes" src={logoFacebook} alt=""/>
                            </span>
                            <span className="white link" onClick={() => openWindow(21)}>
                              <img className="redes" src={logoYouTube} alt=""/>
                            </span>
                            <span className="white link" onClick={() => openWindow(17)}>
                              <img className="redes" src={logoLinkedin} alt=""/>
                            </span>
                            <span className="white link" onClick={() => openWindow(22)}>
                              <img className="redes" src={logoTiktok} alt=""/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row-sio h-75x">
            <div className="cell-sio text-middle text-center">
                <p className="cuerpo white px-15x">Copyright2020 @INTERprotección | <span className="white link "  onClick={() => openWindow(14)}>Aviso de privacidad</span></p>
            </div>
        </div>
    </div>
</div>
    </>
  );
}