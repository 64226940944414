import Inicio from "../Inicio/Inicio";
import Cotizacion from "../Cotizacion/Cotizacion";
import DatosPersonales from "../DatosPersonales/DatosPersonales";
import DatosVehicle from "../DatosVehicle/DatosVehicle";
import ResumenCompra from "../ResumenCompra/ResumenCompra";
import CompraExitosa from "../CompraExitosa/CompraExitosa";
import Pasarela from "../Pasarela/Pasarela";

export const routes = [
  {
    path: "/inicio",
    component: Inicio,
  },
  {
    path: "/cotizacion",
    component: Cotizacion,
  },
  {
    path: "/datos-personales",
    component: DatosPersonales,
  },
  {
    path: "/datos-vehicle",
    component: DatosVehicle,
  },
  {
    path: "/resumen-compra",
    component: ResumenCompra,
  },
  {
    path: "/compra-exitosa",
    component: CompraExitosa,
  },
  {
    path: "/pasarela",
    component: Pasarela,
  },
];