
import { FormControlLabel, Radio } from "@mui/material";
import { useField } from "formik";
import React from "react";

export default function CustomRadio(props) {
  const [field] = useField(props);

  return (
    <FormControlLabel
      {...field}
      {...props}
      control={<Radio />}
      label={props.label}
    />
  );
}
