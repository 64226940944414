import * as yup from "yup";
import { TELEFONO_INVALIDO, TEXT_MAX, VALOR_NECESARIO } from "../Utils/Constantes";

export  const validationForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .nullable(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(35,TEXT_MAX),
  apPaterno: yup
    .string()
    .required(VALOR_NECESARIO)
    .nullable(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(35,TEXT_MAX),
  apMaterno: yup
    .string()
    .nullable(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(35,TEXT_MAX),
  numTarjeta: yup
    .string()
    .required(VALOR_NECESARIO)
    .nullable(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(16,TEXT_MAX),
  fechaVencimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .nullable(VALOR_NECESARIO),
  cvv: yup
    .string()
    .required(VALOR_NECESARIO)
    .nullable(VALOR_NECESARIO)
    .min(3, TELEFONO_INVALIDO)
    .max(4,TEXT_MAX),
  // tipoTarjeta: yup
  //   .string()
  //   .required(VALOR_NECESARIO)
  //   .nullable(VALOR_NECESARIO),
  // indemnizacion: yup
  //   .boolean()
  //   .required(CHECK_NECESARIO)
  //   .nullable(CHECK_NECESARIO),
  // siniestrado: yup
  //   .boolean()
  //   .required(CHECK_NECESARIO)
  //   .nullable(CHECK_NECESARIO),
  // avisoPrivacidad: yup
  //   .boolean()
  //   .required(CHECK_NECESARIO)
  //   .nullable(CHECK_NECESARIO),
  // condicionesGenerales: yup
  //   .boolean()
  //   .required(CHECK_NECESARIO)
  //   .nullable(CHECK_NECESARIO),

 });
