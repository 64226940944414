import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import React from "react";

export default function CustomCheckbox(props) {
  const [field] = useField(props);
  const { values } = useFormikContext();
  const isChecked = values[props.name];

  return (
    <FormControlLabel
      {...field}
      {...props}
      control={<Checkbox checked={isChecked} />}
      label={props.label}
    />
  );
}
