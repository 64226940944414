import React from 'react';
import './Inicio.css';

import logoGadgetSeguro from '../../assets/images/otros/Vida Segura.svg';
import logoMascotaSegura from '../../assets/images/otros/Mascota Segura.svg';
import logoGastosMedicos from '../../assets/images/otros/Medico_Seguro.svg';
import logoBienSeguro from '../../assets/images/otros/Bien Seguro.svg';
import logoCoche from '../../assets/images/banner/cocheseguro1.svg';
import logoCotiza from '../../assets/images/banner/Cotiza.png';
import logoElige from '../../assets/images/banner/Elige.png';
import logoContrata from '../../assets/images/banner/Contrata.png';

import logoAba from '../../assets/images/cias/ABAAutoSeguro.png';
import logoQualitas from '../../assets/images/cias/Qualitas.png';
import logoAxa from '../../assets/images/cias/axa_logo-01.svg';
import logoAfirme from '../../assets/images/cias/AFIRME.png';
import logoHdi from '../../assets/images/cias/HDI.png';
import logoPrimeroSeguros from '../../assets/images/cias/PRIMERO_SEGUROS.png';
import logoMapfre from '../../assets/images/cias/MAPFRE.png';

import logoCocheSeguro from '../../assets/images/coche/Seguro.png';
import logoFAQ from '../../assets/images/coche/FAQ.png';
import logoMejorServicio from '../../assets/images/coche/MejorServicio.png';
import logoSSL from '../../assets/images/coche/SSL.png';
import nuevobanner from '../../assets/images/nuevobanner.jpg';
import bannerMobile from '../../assets/images/rayas-grande.png';

import PhoneIcon from '@mui/icons-material/Phone';
import AddIcon from '@mui/icons-material/Add';
import FloatingButtons from './FloatingButtons';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import RegistroForm from './RegistroForm';
import { useHistory, useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-MNQ9HSZ'
}

TagManager.initialize(tagManagerArgs);
window.dataLayer.push({
  'pageTitle': 'Uber',
  'application': 'Seguros',
  'profileID': '',
  'userStatus': '',
  'tipoSeguro': 'Plus'
});
window.dataLayer.push({
  'event': "formStep0View"
});
const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: "#00142d!important",
    backgroundImage: "url("+nuevobanner+")",
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  },
  textMark: {
    backgroundColor: "#0e1751"
  },
  bannerBkgImageM: {
    backgroundImage: "url("+bannerMobile+")"
  },
  bkgCover: {
    backgroundSize: "cover"
  }
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function Inicio(props) {
  const classes = useStyles();
  let history = useHistory();
  let query = useQuery();
  const guardarRegistroInicioAyuda = () => {
    console.log(guardarRegistroInicioAyuda);
  };

  const openWin = (caso) => {
    try {
      switch (caso) {
        case 1:
          window.open("https://productos.inter.mx/bien-seguro");
          break;
        case 2:
          window.open("https://productos.inter.mx/gastos-medicos-mayores/");
          break;
        case 3:
          window.open("https://mascotasegura.inter.mx/seguro-mascota/landing");
          break;
        case 4:
          window.open("");
          break;
        default:
          break;
      }
    } catch (error) {
      this.snackBar.open("InicioComponent.openWin().error", error, { duration: this.const.SNACK_BARTIME });
    }
  };
  

  return (
    <>
      <div className="body-estruct" >
        <div className={clsx('banner', classes.banner, (!props.isMobile) && classes.bkgCover, (props.isMobile) && classes.bannerBkgImageM)} >
          <div className="container banner-bg-height">
            <div className="row">
              <div className="col-12 d-none d-lg-block col-lg-6  align-self-center">
                <div className="row">
                  <div className="col-12 text-center d-flex">
                    <div className="col-5 text-center">
                      <img className="logoCoche" src={logoCoche} width="200px" height="110px" alt=""/>
                      <p className="intermx-text">INTER.MX</p>
                    </div>
                    <div className="col-2 text-center add-symbol">
                      <AddIcon/>
                    </div>
                    <div className="col-5 text-center">
                      <img className="logoCoche" src={logoAxa} width="200px" height="110px" alt=""/>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <h1 className="white text-center alianza-text">Una alianza para poder ofrecerte un seguro</h1>
                  </div>
                  <div className="col-12 text-center">
                    <h1 className="white text-center app-transporte-text">Especialmente dise&ntilde;ado para socios de aplicaciones de transporte diferente a UBER</h1>
                  </div>
                  <div className={"col-12 text-center "+classes.textMark}>
                    <h1 className="white"><span className="recuadro">R&Aacute;PIDO, F&Aacute;CIL Y SEGURO</span></h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row d-flex align-items-center justify-content-around">
                  <p className="title-pages ocultar-div">Seguro de AUTO</p>
                  <img className="logoCocheTitle ocultar-div" src={logoCoche} alt=""/>
                </div>
                <div className="card-auto mat-elevation-z2" >
                  <RegistroForm history={history} err={query.get("err")}/>
                </div>
              </div>
              <div className="col-12 d-block d-lg-none col-lg-6 align-self-center py-20x">
                <div className="row">
                  <div className="col-12 text-center">
                    <img className="logoCoche" src={logoCoche} width="167px" height="110" alt=""/>
                  </div>
                  <div className="col-12 col-md-4 text-center">
                    <img className="logoCirculares" src={logoCotiza} alt=""/>
                    <h3 className="title">Cotiza</h3>
                    <p className="description">en las mejores
                      <br />aseguradoras
                      <br />al mejor precio</p>
                  </div>
                  <div className="col-12 col-md-4 text-center mt-20x">
                    <img className="logoCirculares" src={logoElige} alt=""/>
                    <h3 className="title">Elige</h3>
                    <p className="description">el plan que mejor
                      <br />cumpla con tus
                      <br />necesidades</p>
                  </div>
                  <div className="col-12 col-md-4 text-center mt-20x">
                    <img className="logoCirculares" src={logoContrata} alt=""/>
                    <h3 className="title">Contrata</h3>
                    <p className="description">en linea de forma
                      <br />totalmente segura</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="slider">
            <div className="slide-track">
            </div>
          </div>
          <FloatingButtons/>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="container coche">
                  <div className="row">
                    <div className="col-12 col-md-4 text-center py-70x">
                      <img className="imgCoche" src={logoCocheSeguro} alt="" />
                      <h1 className="white">Si&eacute;ntete&nbsp;
                      <span className="pink-coche">seguro</span> al<br />pagar en
                        l&iacute;nea</h1>
                      <p className="description white">Tus datos est&aacute;n<br />protegidos<br />con cifrado SSL</p>
                      <img className="imgSeguroSSL" src={logoSSL} alt="" />
                    </div>
                    <div className="col-12 col-md-4 text-center py-70x">
                      <img className="imgCoche" src={logoMejorServicio} alt="" />
                      <h1 className="white">Te brindamos<br />
                      <span className="green-coche">atenci&oacute;n 24/7</span></h1>
                      <p className="description white">Antes y despu&eacute;s de<br />contratar. Te garantizamos<br />la mejor
                        experiencia
                      </p>
                    </div>
                    <div className="col-12 col-md-4 text-center py-70x">
                      <img className="imgCoche" src={logoFAQ} alt="" />
                      <h1 className="white">Consulta nuestras<br />
                      <span className="blue-coche">preguntas frecuentes</span></h1>
                      <a className="link" target="_blank" rel="noreferrer" 
                        href="https://storage.googleapis.com/coche-seguro-inter/Preguntasfrecuentes.pdf">Haz click aqu&iacute;.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
        <div className="container-fluid body-inicio" >
          <div className="row">
            <div className="col-12">
              <div className="container otros-seguros">
                <div className="row justify-content-center">
                  <div className="col-12 text-center">
                    <h1 className="gray">¿Necesitas protecci&oacute;n adicional?</h1>
                    <h2 className="blue-inter">Tenemos estos seguros pensados en ti</h2>
                    <br />
                    <br />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3" onClick={() => openWin(1)}>
                    <div className="cubo-seguros">
                      <div className="table-sio">
                        <div className="row-sio">
                          <div className="cell-sio text-bottom">
                            <img className="imgBienSeguro" src={logoBienSeguro} alt=""/>
                          </div>
                        </div>
                        <div className="row-sio">
                          <div className="cell-sio text-middle">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3" onClick={() => openWin(2)}>
                    <div className="cubo-seguros">
                      <div className="table-sio">
                        <div className="row-sio">
                          <div className="cell-sio text-bottom">
                            <img className="imgBienSeguro" src={logoGastosMedicos} alt="" />
                          </div>
                        </div>
                        <div className="row-sio">
                          <div className="cell-sio text-middle">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3" onClick={() => openWin(3)}>
                    <div className="cubo-seguros">
                      <div className="table-sio">
                        <div className="row-sio">
                          <div className="cell-sio text-bottom">
                            <img className="imgBienSeguro" src={logoMascotaSegura} alt="" />
                          </div>
                        </div>
                        <div className="row-sio">
                          <div className="cell-sio text-middle">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3" onClick={() => openWin(4)}>
                    <div className="cubo-seguros">
                      <div className="table-sio">
                        <div className="row-sio">
                          <div className="cell-sio text-bottom">
                            <img className="imgBienSeguro" src={logoGadgetSeguro} alt="" />
                          </div>
                        </div>
                        <div className="row-sio">
                          <div className="cell-sio text-middle">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row float-right mx-0x">
          <div className="col-12 py-20x">
            <button className="btn-ayuda font-ayuda ocultar-btn" 
              onClick={guardarRegistroInicioAyuda}>
              <PhoneIcon/> Ayuda
            </button>
          </div>
        </div>
      </div>

    </>
  );
}
