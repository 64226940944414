
import CotizarApi from '../Api/Cotizacion';
import { calcularEdad } from './Utils';

export function recotizar(uberSeguro, typePlan, setLoading, setFieldValue, fieldName, fieldValue, values) {
  var tipoPersona, sexo;
  if (uberSeguro.tipoPersona === 1) {
    tipoPersona = "FISICA";
  } else {
    tipoPersona = "MORAL";
  }
  var fechaHasta = new Date();
  var mes = (fechaHasta.getMonth()+1);
  var dia = fechaHasta.getDate();
  if (mes <10){
    mes = '0' + mes;
  }
  if (dia <10){
    dia = '0' + dia;
  }
  var fechaDesde = dia+"/" + mes + "/" + fechaHasta.getFullYear();
  fechaHasta = dia+"/" + mes + "/" + (fechaHasta.getFullYear()+1);
  var edadCotiza = 0;
  
  if(fieldName === "fechaNacimiento"){
    edadCotiza = calcularEdad(fieldValue);
    if (values.genero === 0){
      sexo = "MASCULINO";
    } else {
      sexo = "FEMENINO";
    }
  }

  if(fieldName === "genero"){
    edadCotiza = calcularEdad(values.fechaNacimiento);
    if (fieldValue === 0){
      sexo = "MASCULINO";
    } else {
      sexo = "FEMENINO";
    }
  }

  const params = {
      "FechaDesde": fechaDesde,
      "FechaHasta": fechaHasta,
      "TipoPersona": tipoPersona,
      "Nombre": uberSeguro.datosPersonales.nombre,
      "Telefono": uberSeguro.datosPersonales.telefono,
      "Email": uberSeguro.datosPersonales.correo,
      "CodigoPostal": uberSeguro.domicilio.cp,
      "ClaveEstado": uberSeguro.domicilio.estado,
      "ClavePoblacion": uberSeguro.domicilio.municipio,
      "Sexo": sexo,
      "Edad": edadCotiza,
      "FormaPago": typePlan.FormaPago,
      "Modelo": uberSeguro.auto.model,
      "Uso": "U",
      "Marca": uberSeguro.auto.bran,
      "Tipo": uberSeguro.auto.type,
      "Clase": uberSeguro.auto.class
  }
  const paramsAPP = {
      "FechaDesde": fechaDesde,
      "FechaHasta": fechaHasta,
      "TipoPersona": tipoPersona,
      "Nombre": uberSeguro.datosPersonales.nombre,
      "Telefono": uberSeguro.datosPersonales.telefono,
      "Email": uberSeguro.datosPersonales.correo,
      "CodigoPostal": uberSeguro.domicilio.cp,
      "ClaveEstado": uberSeguro.domicilio.estado,
      "ClavePoblacion": uberSeguro.domicilio.municipio,
      "Sexo": sexo,
      "Edad": edadCotiza,
      "FormaPago": typePlan.FormaPago,
      "Modelo": uberSeguro.auto.model,
      "Uso": "APP",
      "Marca": uberSeguro.auto.bran,
      "Tipo": uberSeguro.auto.type,
      "Clase": uberSeguro.auto.class
  }
  recotiza(params, paramsAPP, setLoading, setFieldValue, fieldName, fieldValue);

}

function recotiza (params, paramsAPP, setLoading, setFieldValue, fieldName, fieldValue){
  CotizarApi.cotizar(params)
    .then(res => {
      window.sessionStorage.setItem("U",JSON.stringify(res.data.data));
      setFieldValue(fieldName, fieldValue);
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  CotizarApi.cotizar(paramsAPP)
    .then(res => {
      window.sessionStorage.setItem("APP",JSON.stringify(res.data.data));
      setFieldValue(fieldName, fieldValue);
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
}

