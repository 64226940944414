

//export const PATH = "https://skynet.copsis.com:8181/sio4amarketplace/";
export const PATH = "https://lb3.copsis.com/";
//export const APPSPOT_PATH = "https://inter-uber.uc.r.appspot.com"; //QA
export const APPSPOT_PATH = "https://inter-driver-prod.uc.r.appspot.com"; //Producción

export const defaultParams = {
  m: "zl8mIfg3UrGeD1CvQu7UddMRtZ9eljnfGDTy+oOne/8=",
  s4_key: "uLmdvb2dsZS5jb20vY2hhdC1kYXRhYmFzZS05NTdjMSIsIm5hbWUiOiJIZWJlc",
  f1: null,
}

//validaciones
export const VACIO = "";
export const UN_CRITERIO = "Debe proporcionar al menos un crtierio de búsqueda.";
export const VALOR_NECESARIO = "Ingrese un valor";
export const CMB_NECESARIO = "Seleccione una opción valida";
export const VALOR_POSITIVO = "Ingrese un valor positivo";
export const VALOR_NUMERO = "Ingrese un número";
export const VALOR_ENTERO = "Ingrese un número entero";
export const CORREO_INVALIDO = "Ingrese un correo valido";
export const TELEFONO_INVALIDO = "Ingrese un número valido";
export const CP_INVALIDO = "Ingrese un código postal valido";
export const EDAD_INVALIDA = "Ingrese una edad valida";
export const CMB_MENOS_UNO = -1;
export const MIN_LETRAS_DES_AUTO = 3;
export const MAX_LENGTH_CP = 5;
export const CHECK_NECESARIO = "Seleccione una opción";
export const MAX_LENGTH_NUM_TARJETA = 16;
export const MAX_LENGTH_CVV = 4;
export const ERROR_MSG = "Te pedimos una disculpa, hubo un error al realizar tu consulta.";
export const ERROR_TITULO_MSG = "¡Sucedió un Error!";
export const ERROR_MSG_1 = "El número de serie ya esta amparado en otra poliza";
export const ERROR_TITULO_MSG_1 = "Validación de número de serie";
export const MAX_LENGTH_NO_SERIE = 17;
export const MAX_LENGTH_PLACAS = 7;
export const MAX_LENGTH_RFC = 13;
export const MAX_LENGTH_RFC_M = 12;
export const MAYOR_EDAD = "Debe ser mayor de edad";
export const AUTOCOMPLETE = "Debe escribir para buscar su auto";

export const TEXT_MAX = 'Debe tener como máximo ${max} caracteres';
export const TEXT_MIN = 'Debe tener ${min} caracteres';
export const TEXT_MIN_MAX = 'Debe tener ${max} caracteres';