import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../Forms/CustomTextField';
import { Form, Formik } from 'formik';
import { validationDialogTelefonoAyuda } from './ValidationsFormYup';
import { handleMaxLength } from '../Utils/FieldValidationFuctions';


export default function FormDialogNoEncuentroAuto(props) {

  const [botonActivo, setBotonActivo] = React.useState(false);

  
  const handleClickModal = () => {
    props.handleClose();
  }

  const handleAyuda = (values, setSubmitting) => {
    let uberAyuda = {};
    if(window.sessionStorage.getItem("uberSeguro")){
      uberAyuda = JSON.parse(sessionStorage.getItem("uberSeguro"));
      console.log("uberAyuda",uberAyuda);
    }
    uberAyuda.telefonoAyuda = values.telefonoAyuda;
    window.sessionStorage.setItem("uberAseguro", JSON.stringify(uberAyuda));
    setSubmitting(false);
    props.handleClose();
  }



  const handleOnchange = (event) => {
    let numero = event;
    let longituNumero = (numero.toString().length);
    if(longituNumero === '' || longituNumero < 10){
      setBotonActivo(false);
    }else{
      setBotonActivo(true);
    }
  }



  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          telefonoAyuda: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleAyuda(values, setSubmitting);
        }}
        validationSchema={validationDialogTelefonoAyuda}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError, touched, setFieldTouched, }) => (
          <Form>
            <IconButton
              aria-label="close"
              onClick={handleClickModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 0,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="modal-individual-padding">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <br />
                  <span className='modal-titulo'>Recibe asesor&iacute;a </span>
                  <span className='modal-titulo'>personalizada</span>
                  <span className='modal-titulo'>Puedes marcarnos al:</span>
                  <span className='modal-titulo-telefono'>55442 46837</span>
                  {/*<span className='modal-titulo'>O escribe tu n&uacute;mero y nos</span>
                  <span className='modal-titulo'>comunicamos contigo</span>


                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <CustomTextField id="telefonoAyuda" name="telefonoAyuda" type="number" placeholder="Tel&eacute;fono/Celular"
                      onChange={(event) => { handleMaxLength(event, setFieldValue, "telefonoAyuda", 10); handleOnchange(event.target.value) }}

                    />
                  </Box>

                  <Button
                    variant="contained"
                    onClick={submitForm}
                    disabled={!botonActivo}
                    style={{ textTransform: 'capitalize' }}>
                    Quiero que me hablen
                  </Button>*/}

                  <span className='modal-text'>Horario de atenci&oacute;n:</span>
                  <span className='modal-text'>De lunes a domingo de 8:00 a 20:00</span>
                  <span className='modal-text'>hrs.</span>

                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}