import axios from "axios";
import { APPSPOT_PATH } from "../Utils/Constantes";

// Create instance called instance
const instance = axios.create({
  baseURL: APPSPOT_PATH,
});

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    cotizar: (params) =>
    instance({
      method: "POST",
      url:"/cotizacion",
      data: params,
    }),
  }