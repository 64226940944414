import * as yup from "yup";
import { CP_INVALIDO, MAX_LENGTH_RFC, MAX_LENGTH_RFC_M, MAYOR_EDAD, TEXT_MAX, VALOR_NECESARIO } from "../Utils/Constantes";
import { validaMayorEdad } from "../Utils/Utils";

export  const validationFisicaForm = yup.object().shape({
    nombre: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    apPaterno: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    apMaterno: yup
      .string()
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    fechaNacimiento: yup
      .date()
      .required(VALOR_NECESARIO)
      .test(
        "mayorEdad",
        MAYOR_EDAD,
        (val) => {          
          if(validaMayorEdad(val)){
            return true;
          } else {
            return false;
          }
        }
      ),
    genero: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    rfc: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(MAX_LENGTH_RFC,TEXT_MAX),
    telefono: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    correo: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(50,TEXT_MAX),
    calle: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(40,TEXT_MAX),
    numExt: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(10,TEXT_MAX),
    numInt: yup
      .string()
      .min(1, VALOR_NECESARIO)
      .max(10,TEXT_MAX),
    cp: yup
      .string()
      .required(CP_INVALIDO)
      .typeError(CP_INVALIDO)
      .length(5,CP_INVALIDO)
      .test(
        "formato",
        CP_INVALIDO,
        (val) => {
          var regex = /^\D*\d{5}$/;
          return regex.test(val);
        }
      ),
    colonia: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(100,TEXT_MAX),
    ciudad: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(100,TEXT_MAX),    
  });


  export  const validationMoralForm = yup.object().shape({
    nombre: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    rfc: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(MAX_LENGTH_RFC_M,TEXT_MAX),
    telefono: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(20,TEXT_MAX),
    correo: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(50,TEXT_MAX),
    calle: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(40,TEXT_MAX),
    numExt: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(10,TEXT_MAX),
    numInt: yup
      .string()
      .min(1, VALOR_NECESARIO)
      .max(10,TEXT_MAX),    
    cp: yup
    .string()
    .required(CP_INVALIDO)
    .typeError(CP_INVALIDO)
    .length(5,CP_INVALIDO)
    .test(
      "formato",
      CP_INVALIDO,
      (val) => {
        var regex = /^\D*\d{5}$/;
        return regex.test(val);
      }
    ),
    colonia: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(100,TEXT_MAX),
    ciudad: yup
      .string()
      .required(VALOR_NECESARIO)
      .min(1, VALOR_NECESARIO)
      .max(100,TEXT_MAX),    
  });