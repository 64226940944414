import React, { useEffect, useState } from 'react';
import logoCoche from '../../assets/images/banner/cocheseguro1.svg';
import logoAxa from '../../assets/images/cias/axa_logo-01.svg';
import Cotizar from '../Api/Cotizacion';
import { FormatNumber } from '../Utils/Utils';
import './Cotizacion.css';
import Loading from '../Estructura/Modal/Loading';

var plataforma = [];
var cp = 0;
var params = {};
var paramsAPP = {};
var tipoPersona = '';
var sexo = '';

window.dataLayer.push({
  'event': 'formStep1View'
});

export default class Cotizacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uberSeguro: {},
      resultadoVehicle: [],
      resultadoVehicleAPP: [],
      resultadoCoberturas: '',
      resultadoCoberturasAPP: '',
      resultadoTotal: [],
      resultadoTotalAPP: [],
      resultadoRecibo: 0,
      resultadoreciboAPP: 0,
      resultadoSub: 0,
      resultadoSubAPP: 0,
      resultadoModalidad: '',
      resultadoModalidadAPP: '', 
      isLoading: true,
    }
  }

  handleContinue = (event) => {
    var tipo = '';
    if (event.target.id === 'btn_U'){
      tipo = 'U';
    } else {
      tipo = 'APP';
    }
    window.sessionStorage.setItem("type",tipo);
    if(window.sessionStorage.getItem("type")){
        const typePlan = window.sessionStorage.getItem("type");
        if(window.sessionStorage.getItem(typePlan)){
          const cotizacionJson = JSON.parse(window.sessionStorage.getItem(typePlan));
          window.dataLayer.push({
            'event': "addToCart",
           'eventCategory': 'Formulario.Seguro.AutoApp',
           'eventAction': 'Step1',
           'eventLabel': 'AgregarACarrito',
           'FormaDePago': cotizacionJson.FormaPago
          });
          window.dataLayer.push({
            "event" : "cocheapp_seguro_contratar",      //Static data,
            "aseguradora": 'Axa',
            "modalidad_pago": cotizacionJson.FormaPago,
            "Costo_anual": cotizacionJson['Importes-Recibos'].primer_recibo.PrimaTotal,
            "Cotizacion": cotizacionJson.SolicitudID,
            "boton_contratar":"Contratar"

          });
        }
      }
    this.props.history.push("/datos-personales");
  }
  
  handleChange = (event) => {
    const isLoading = true;
    this.setState({ isLoading });
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguro = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      this.setState( {uberSeguro});
      //plataforma = uberSeguro.plataformas;
      cp = uberSeguro.domicilio.cp; 
      if (uberSeguro.tipoPersona == 1) {
        tipoPersona = "FISICA";
      } else {
        tipoPersona = "MORAL";
      }
      if (tipoPersona === "FISICA") {
        if (uberSeguro.datosPersonales.genero == 0){
          sexo = "MASCULINO";
        } else {
          sexo = "FEMENINO";
        }
      }
      var fechaHasta = new Date();
      var mes = (fechaHasta.getMonth()+1);
      var dia = fechaHasta.getDate();
      if (mes <10){
        mes = '0' + mes;
      }
      if (dia <10){
        dia = '0' + dia;
      }
      var fechaDesde = dia+"/" + mes + "/" + fechaHasta.getFullYear();
      fechaHasta = dia+"/" + mes + "/" + (fechaHasta.getFullYear()+1);
      var edadCotiza = 0;
      if (tipoPersona === "FISICA") {
        edadCotiza = uberSeguro.datosPersonales.edad;
      }

      params = {
          "FechaDesde": fechaDesde,
          "FechaHasta": fechaHasta,
          "TipoPersona": tipoPersona,
          "Nombre": uberSeguro.datosPersonales.nombre,
          "Telefono": uberSeguro.datosPersonales.telefono,
          "Email": uberSeguro.datosPersonales.correo,
          "CodigoPostal": uberSeguro.domicilio.cp,
          "ClaveEstado": uberSeguro.domicilio.estado,
          "ClavePoblacion": uberSeguro.domicilio.municipio,
          "Sexo": sexo,
          "Edad": edadCotiza,
          "FormaPago": event.target.value,
          "Modelo": uberSeguro.auto.model,
          "Uso": "U",
          "Marca": uberSeguro.auto.bran,
          "Tipo": uberSeguro.auto.type,
          "Clase": uberSeguro.auto.class
      }
      paramsAPP = {
          "FechaDesde": fechaDesde,
          "FechaHasta": fechaHasta,
          "TipoPersona": tipoPersona,
          "Nombre": uberSeguro.datosPersonales.nombre,
          "Telefono": uberSeguro.datosPersonales.telefono,
          "Email": uberSeguro.datosPersonales.correo,
          "CodigoPostal": uberSeguro.domicilio.cp,
          "ClaveEstado": uberSeguro.domicilio.estado,
          "ClavePoblacion": uberSeguro.domicilio.municipio,
          "Sexo": sexo,
          "Edad": edadCotiza,
          "FormaPago": event.target.value,
          "Modelo": uberSeguro.auto.model,
          "Uso": "APP",
          "Marca": uberSeguro.auto.bran,
          "Tipo": uberSeguro.auto.type,
          "Clase": uberSeguro.auto.class
      }
    }

    const handle = (params)  =>{
      Cotizar.cotizar(params)
        .then(res => {
          const isLoading = false;
          this.setState({ isLoading });
          const resultadoVehicle = res.data.data.Vehiculo;
          this.setState({ resultadoVehicle });
          const resultadoTotal = res.data.data['Importes-Recibos'].primer_recibo;
          this.setState({ resultadoTotal });
          const resultadoRecibo = res.data.data.RecibosSubsecuentes;
          this.setState({ resultadoRecibo });
          const resultadoSub = res.data.data['Importes-Recibos'].recibos_subsecuentes;
          this.setState({ resultadoSub });
          const resultadoModalidad = res.data.data.FormaPago;
          this.setState({ resultadoModalidad });
          const coberturas = res.data.data.Coberturas;
          var arr = [];
          Object.keys(coberturas).forEach(function(key) {
            arr.push(coberturas[key]);
          });
          const resultadoCoberturas = arr.map((item, key) => <MyAppChild key={key} nombre={item.Nombre.value} suma={item.SumaAsegurada.value} deducible={item.Deducible.value} />);
          this.setState({ resultadoCoberturas });
          window.sessionStorage.setItem("U",JSON.stringify(res.data.data));
        })
        .catch(error => {
          const isLoading = false;
          this.setState({ isLoading });
          console.log(error);
        });      
    };

    const handleAPP = (paramsAPP)  =>{
      Cotizar.cotizar(paramsAPP)
        .then(res => {
          const isLoading = false;
          this.setState({ isLoading });
          const resultadoVehicleAPP = res.data.data.Vehiculo;
          this.setState({ resultadoVehicleAPP });
          const resultadoTotalAPP = res.data.data['Importes-Recibos'].primer_recibo;
          this.setState({ resultadoTotalAPP });
          const resultadoreciboAPP = res.data.data.RecibosSubsecuentes;
          this.setState({ resultadoreciboAPP });
          const resultadoSubAPP = res.data.data['Importes-Recibos'].recibos_subsecuentes
          this.setState({ resultadoSubAPP });
          const resultadoModalidadAPP = res.data.data.FormaPago;
          this.setState({ resultadoModalidadAPP });
          var arr = [];
          const coberturas = res.data.data.Coberturas;
          Object.keys(coberturas).forEach(function(key) {
            arr.push(coberturas[key]);
          });
          const resultadoCoberturasAPP = arr.map((item, key) => <MyAppChild key={key} nombre={item.Nombre.value} suma={item.SumaAsegurada.value} deducible={item.Deducible.value} />);
          this.setState({ resultadoCoberturasAPP });
          window.sessionStorage.setItem("APP",JSON.stringify(res.data.data));
        })
        .catch(error => {
          const isLoading = false;
          this.setState({ isLoading });
          console.log(error);
        });      
    };
    handleAPP(paramsAPP);
    handle(params);
  }

  componentDidMount() {
    if(window.sessionStorage.getItem("uberSeguro")){
      const uberSeguro = JSON.parse(window.sessionStorage.getItem("uberSeguro"));
      this.setState( {uberSeguro});
      //plataforma = uberSeguro.plataformas;
      cp = uberSeguro.domicilio.cp; 
      if (uberSeguro.tipoPersona == 1) {
        tipoPersona = "FISICA";
      } else {
        tipoPersona = "MORAL";
      }
      if (tipoPersona === "FISICA") {
        if (uberSeguro.datosPersonales.genero == 0){
          sexo = "MASCULINO";
        } else {
          sexo = "FEMENINO";
        }
      }
      var fechaHasta = new Date();
      var mes = (fechaHasta.getMonth()+1);
      var dia = fechaHasta.getDate();
      if (mes <10){
        mes = '0' + mes;
      }
      if (dia <10){
        dia = '0' + dia;
      }
      var fechaDesde = dia+"/" + mes + "/" + fechaHasta.getFullYear();
      fechaHasta = dia+"/" + mes + "/" + (fechaHasta.getFullYear()+1);
      var edadCotiza = 0;
      if (tipoPersona === "FISICA") {
        edadCotiza = uberSeguro.datosPersonales.edad;
      }
      params = {
          "FechaDesde": fechaDesde,
          "FechaHasta": fechaHasta,
          "TipoPersona": tipoPersona,
          "Nombre": uberSeguro.datosPersonales.nombre,
          "Telefono": uberSeguro.datosPersonales.telefono,
          "Email": uberSeguro.datosPersonales.correo,
          "CodigoPostal": uberSeguro.domicilio.cp,
          "ClaveEstado": uberSeguro.domicilio.estado,
          "ClavePoblacion": uberSeguro.domicilio.municipio,
          "Sexo": sexo,
          "Edad": edadCotiza,
          "FormaPago": "CONTADO",
          "Modelo": uberSeguro.auto.model,
          "Uso": "U",
          "Marca": uberSeguro.auto.bran,
          "Tipo": uberSeguro.auto.type,
          "Clase": uberSeguro.auto.class
      }
      paramsAPP = {
          "FechaDesde": fechaDesde,
          "FechaHasta": fechaHasta,
          "TipoPersona": tipoPersona,
          "Nombre": uberSeguro.datosPersonales.nombre,
          "Telefono": uberSeguro.datosPersonales.telefono,
          "Email": uberSeguro.datosPersonales.correo,
          "CodigoPostal": uberSeguro.domicilio.cp,
          "ClaveEstado": uberSeguro.domicilio.estado,
          "ClavePoblacion": uberSeguro.domicilio.municipio,
          "Sexo": sexo,
          "Edad": edadCotiza,
          "FormaPago": "CONTADO",
          "Modelo": uberSeguro.auto.model,
          "Uso": "APP",
          "Marca": uberSeguro.auto.bran,
          "Tipo": uberSeguro.auto.type,
          "Clase": uberSeguro.auto.class
      }
    }

    const handle = (params)  =>{
      Cotizar.cotizar(params)
        .then(res => {
          const isLoading = false;
          this.setState({ isLoading });
          const resultadoVehicle = res.data.data.Vehiculo;
          this.setState({ resultadoVehicle });
          const resultadoTotal = res.data.data['Importes-Recibos'].primer_recibo;
          this.setState({ resultadoTotal });
          const resultadoRecibo = res.data.data.RecibosSubsecuentes;
          this.setState({ resultadoRecibo });
          const resultadoSub = res.data.data['Importes-Recibos'].recibos_subsecuentes;
          this.setState({ resultadoSub });
          const resultadoModalidad = res.data.data.FormaPago;
          this.setState({ resultadoModalidad });
          const coberturas = res.data.data.Coberturas;
          var arr = [];
          Object.keys(coberturas).forEach(function(key) {
            arr.push(coberturas[key]);
          });
          const resultadoCoberturas = arr.map((item, key) => <MyAppChild key={key} nombre={item.Nombre.value} suma={item.SumaAsegurada.value} deducible={item.Deducible.value} />);
          this.setState({ resultadoCoberturas });
          window.sessionStorage.setItem("U",JSON.stringify(res.data.data));
        })
        .catch(error => {
          console.log(error);
          this.props.history.push("/inicio?err=true");
        });      
    };

    const handleAPP = (paramsAPP)  =>{
      Cotizar.cotizar(paramsAPP)
        .then(res => {
          const resultadoVehicleAPP = res.data.data.Vehiculo;
          this.setState({ resultadoVehicleAPP });
          const resultadoTotalAPP = res.data.data['Importes-Recibos'].primer_recibo;
          this.setState({ resultadoTotalAPP });
          const resultadoreciboAPP = res.data.data.RecibosSubsecuentes;
          this.setState({ resultadoreciboAPP });
          const resultadoSubAPP = res.data.data['Importes-Recibos'].recibos_subsecuentes
          this.setState({ resultadoSubAPP });
          const resultadoModalidadAPP = res.data.data.FormaPago;
          this.setState({ resultadoModalidadAPP });
          var arr = [];
          const coberturas = res.data.data.Coberturas;
          Object.keys(coberturas).forEach(function(key) {
            arr.push(coberturas[key]);
          });
          const resultadoCoberturasAPP = arr.map((item, key) => <MyAppChild key={key} nombre={item.Nombre.value} suma={item.SumaAsegurada.value} deducible={item.Deducible.value} />);
          this.setState({ resultadoCoberturasAPP });
          window.sessionStorage.setItem("APP",JSON.stringify(res.data.data));
        })
        .catch(error => {
          console.log(error);
          this.props.history.push("/inicio?err=true");
        });      
    };
    handleAPP(paramsAPP);
    handle(params);
  }

  nuevaCotizacion() {
    window.sessionStorage.clear();
    window.location.replace("/inicio");
  }

  render() {
    return (
      <>
        <Loading
          open={this.state.isLoading}
          handleClose={() => this.state.isLoading}
        />
        <div className="body-estruct">
        <div className="header-section" >
          <div className="container">
              <div className="row minh-100x">
                  <div className="col-12 col-md-2 col-xl-2 text-center">
                      <img className="logoCoche" src={logoCoche} width="150px" height="70px" alt=''/>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4 align-self-center">
                      <div className="col-12 align-self-center">
                          <div className="contenedor">
                              <div className="lbl-value line">Est&aacute;s cotizando un seguro para:</div>
                          </div>
                          <div className="contenedor">
                              <div className="lbl-value">{this.state.resultadoVehicle.Descripcion}</div>
                          </div>
                          <div className="table-sio">
                              <div className="cell-sio px-5x">
                                  <div className="contenedor">
                                      <div className="lbl-title">A&Ntilde;O:</div>
                                      <div className="lbl-value">{this.state.resultadoVehicle.Modelo}</div>
                                  </div>
                              </div>
                              <div className="cell-sio px-5x">
                                  <div className="contenedor">
                                      <div className="lbl-title">C&Oacute;DIGO POSTAL:</div>
                                      <div className="lbl-value">{cp}</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                      &nbsp;
                  </div>
                  <div className="col-12 col-md-2 col-xl-2 text-center  d-flex align-center">
                    <div id="btn_regresar_cotizacion" className="btn-back" onClick={this.nuevaCotizacion}> Nueva Cotizaci&oacute;n</div>
                  </div>
              </div>
          </div>
        </div>
        <div className="container py-20x">
          <div className="row">
            {plataforma.length < 2 && plataforma[0] == 6 &&
              <div className="col-12 col-md-6 col-xl-2 px-xs-2x">
              </div>
            }
            {plataforma.length == 1 &&  plataforma[0] != 6 &&
              <div className="col-12 col-md-3 col-xl-3 px-xs-3x">
              </div>
            }
              <div className="col-12 col-md-3 col-xl-3 px-xs-3x">
              </div>
              <div className="col-12 col-md-6 col-xl-6 px-xs-6x table-containerapp">
                <div className="row p-20x">
                  <div className="col-6 col-md-6 col-xl-5 px-xs-5x">
                      <img alt='logo_cia' className="logo-header" src={logoAxa} width="150px" height="70px"/>
                  </div>
                  <div className="col-4 col-md-6 col-xl-5 px-xs-5x app24">
                    PROTECT APP
                  </div>
                </div>
                <div className="row p-20x app12">
                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                    *Aplica para socios Didi, Cabify, Easy taxi y Beat
                  </div>
                </div>
                <div className="row p-20x app16">
                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                  {this.state.resultadoVehicleAPP.Descripcion}
                  </div>
                </div>
                <div className="row p-20x app36">
                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                    <FormatNumber number={this.state.resultadoTotalAPP.PrimaTotal} />
                  </div>
                </div>
                {this.state.resultadoreciboAPP > 0 &&
                    <div className="row app16">
                      <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                        + {this.state.resultadoreciboAPP} pago(s) de <FormatNumber number={(this.state.resultadoSubAPP.PrimaTotal/this.state.resultadoreciboAPP)} />
                      </div>
                    </div>
                    }
                    {this.state.resultadoreciboAPP < 1&&
                    <div className="row app16">
                      <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                        Costo Total
                      </div>
                    </div>
                    }
                <div className="row p-20x">
                    <div className="col-6 col-md-6 col-xl-6 px-xs-6x app18">
                      Modalidad de pago
                    </div>
                    <div className="col-6 col-md-6 col-xl-6 px-xs-6x">
                      <select placeholder="Modalidad" name="modalidadAPP" id="cbModalidadAPP" className="m-auto" value={this.state.resultadoModalidadAPP || ''} onChange={this.handleChange}>
                        <option value="CONTADO">Contado</option>
                        <option value="SEMESTRAL">Semestral</option>
                        <option value="TRIMESTRAL">Trimestral</option>
                        <option value="MENSUAL">Mensual</option>
                      </select>
                    </div>
                </div>
                <div className="row p-20x">
                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x align-self-center text-center">
                    <button id="btn_APP"  className="white button-contrata" onClick={this.handleContinue}>Contratar</button>
                  </div>
                </div>
                <div className="row p-20x">
                  <div className="col-12 col-md-12 col-xl-12 px-xs-12x app12">
                  </div>
                </div>
                <div className="row p-20x">
                  <div className="col-1 col-md-1 col-xl-1 px-xs-1x appcolor app16">
                    Coberturas
                  </div>
                </div>
                <div className="row p-20x">
                    <div className="col-1 col-md-1 col-xl-1 px-xs-1x">
                    </div>
                    <div className="col-5 col-md-5 col-xl-5 px-xs-5x appcolorTable">
                      Cobertura
                    </div>
                    <div className="col-3 col-md-3 col-xl-3 px-xs-3x appcolorTable">
                      Suma Asegurada
                    </div>
                    <div className="col-3 col-md-3 col-xl-3 px-xs-3x appcolorTable">
                      Deducible
                    </div>
                </div>
                {this.state.resultadoCoberturasAPP}
              </div>
            {plataforma.length >= 2 &&
              <div className="col-12 col-md-3 col-xl-3 px-xs-3x">
              </div>
            }
          </div>
        </div>
        </div>
      </>
    )
  }
}
class MyAppChild extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-1 col-md-1 col-xl-1 px-xs-1x"></div>
        <div className="col-5 col-md-5 col-xl-5 px-xs-5x">{this.props.nombre}</div>
        <div className="col-3 col-md-3 col-xl-3 px-xs-3x"><FormatNumber number={this.props.suma} /></div>
        <div className="col-3 col-md-3 col-xl-3 px-xs-3x">{this.props.deducible}</div>
      </div>
    )
  }
}