import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      color: "red" // or black
    },
    '& .MuiFilledInput-input': {
      backgroundColor: 'unset',
      border: '1px solid #ced4da'
    },

    'input': {
      color: 'red',
      '&::placeholder': {
        color: 'blue !important'
      }
    }
  }
}));

export default function CustomTextField({ placeholder, ...props }) {
  const [field, meta] = useField(props);
  const classes = useStyles();
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      size="small"
      placeholder={placeholder}
      {...field}
      {...props}
      helperText={errorText}
      error={!!errorText}
      className={classes.root}
      InputProps={{ classes: {input: classes.root['input'] } }}
    />
  );
}

CustomTextField.defaultProps = {
  variant: "filled",
};
