import './Modal.css';
import { blue } from '@material-ui/core/colors';
import { CircularProgress } from '@mui/material';
import Modal from '@mui/material/Modal';
import logoCoche from '../../../assets/images/banner/cocheseguro1.svg';

export default function Loading(props) {

  return (
    <Modal
      size="lg"
      open={props.open}
      onClose={props.handleClose}
    >
      <div className="modal-contenedor">
        <div className="modal-loading">
          <img className="logoCoche" src={logoCoche} width="200px" height="110px" alt=""/>
          <CircularProgress
            size={110}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: 0,
              left: -10,
              zIndex: 1,
            }}
          />
        </div>
      </div>
    </Modal>
  );
}