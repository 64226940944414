
import { Select } from "@mui/material";
import { useField } from "formik";
import React from "react";
import CustomErrorMessage from "./CustomErrorMessage";

export default function CustomSelect({ placeholder, ...props }) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <Select {...field} {...props} error={!!errorText} />
      <CustomErrorMessage name={field.name}/>
    </>
  );
}
